.AdviceInpOut {
    padding: 30px;
    position: relative;
}
.wrap {
    width: 80%;
}

.AdviceInpOut > .wrap > button {
    display: block;
    border: none;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    border-radius: 2px;
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
}
.AdviceInpOut > .wrap > button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

@media (max-width: 440px) {
    .AdviceInpOut {
        padding: 10px;
        position: relative;
    }

    .AdviceInpOut .wrap button {
        display: block;
        margin: 10px auto;
        font-size: 12px;
        padding: 6px 30px;
    }

    .wrap {
        width: 100%;
    }
}
