.MedicineInpOut {
    padding: 15px;
}

@media (max-width: 440px) {
    .MedicineInpOut {
        padding: 14px 8px;
        height: 22em;
    }
}
