.InputMedicine {
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 25px;
}
/* .InputMedicine input {
    padding: 15px 5px 5px 10px;
    font-size: 12px;
} */
.InputMedicine label span {
    font-size: 12px;
}
.InputMedicine input:focus + label span,
.InputMedicine input:valid + label span {
    font-size: 10px;
}
.InputMedicine .formWrap input[type='number'] {
    padding: 17px 10px 5px 10px;
    font-size: 12px;
}
.InputMedicine .formWrap input[type='number'] + label {
    top: 16px;
}
.InputMedicine .formWrap input:focus + label,
.InputMedicine .formWrap input:valid + label {
    top: 2px;
}
.InputMedicine .formWrap select {
    font-size: 12px;
}

.formWrap {
    box-sizing: border-box;
}
.formWrap > div {
    margin-bottom: 10px;
}
.typeWrap {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 70%);
    gap: 10px;
}
.typeWrap > div {
    margin-bottom: 10px;
}
.timeWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}
.timeWrap > div {
    width: 100%;
}
.daysWrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 10px;
}

.remark {
    width: 100%;
}
.InputMedicine button {
    display: block;
    border: none;
    outline: 0;
    background: var(--primary);
    color: var(--white);
    border-radius: 2px;
    padding: 7px 20px;
    margin: 0 auto;
    min-width: 150px;
}
.InputMedicine button {
    cursor: pointer;
    box-shadow: 2px 2px 5px var(--grey2);
}

.continue {
    display: flex;
    justify-content: flex-end;
    margin: -6px 0px 4px;
    color: var(--dark);
    align-items: center;
}

.continue > input[type='checkbox'] {
    cursor: pointer;
}
.continue > label {
    font-size: 13px;
}

.select > select {
    width: 100%;
    cursor: pointer;
    padding: 10px;
    background-color: var(--light);
    color: var(--dark);
    border: none;
    outline: none;
    border-bottom: 2px solid var(--light);
}

.select > select:focus {
    outline: 0;
}

.select > select:hover {
    border-bottom: 2px solid var(--primary);
    transition: 200px;
}

.select > select > option {
    cursor: pointer;
}

@media (max-width: 440px) {
    .InputMedicine {
        padding: 2px;
    }

    .typeWrap {
        grid-template-columns: repeat(3, 1fr);
        gap: 4px;
    }

    .timeWrap {
        grid-template-columns: repeat(3, minmax(0, 33%));
        gap: 4px;
    }

    .InputMedicine button {
        font-size: 12px;
        margin-top: -10px;
    }

    .continue {
        display: flex;
        justify-content: flex-end;
        margin: -6px 0px 4px;
        color: var(--dark);
        align-items: center;
        margin-bottom: 6px;
        padding-right: 3em;
    }

    .continue > input[type='checkbox'] {
        display: inline-block;
        margin-top: -3em;
    }
    .continue > label {
        display: inline-block;
        margin-top: -3.3em;
    }
}
