.HistoryInp {
    padding: 30px;
}
.wrap {
    width: 90%;
}
.wrap h4 {
    color: var(--dark-blue);
    margin: 20px 0;
    cursor: pointer;
    position: relative;
    padding-left: 15px;
    display: block;
    font-weight: 400;
}
.wrap h4::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 0;
    top: 8px;
    border-left: 7px solid var(--dark-blue);
    border-top: 5px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid transparent;
}
.wrap > button,
.btn {
    display: block;
    border: none;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    border-radius: 4px;
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
}
.wrap > button:hover,
.btn:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}
