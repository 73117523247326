.Nav {
    overflow: hidden;
    border-bottom: 1px solid var(--light-grey);
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);
}
.alignLeft {
    display: inline-block;
}
.alignLeft svg {
    color: var(--light-grey);
    margin: 12px 0;
    font-size: 28px;
    cursor: pointer;
}
.alignLeft svg:hover {
    color: var(--secondary);
}

.patient {
    display: inline-block;
    margin: 0 0 0 35px;
    position: relative;
    cursor: pointer;
    width: 80%;
}

.patient:hover {
    opacity: 0.8;
}

.patient svg {
    font-size: 30px;
    position: absolute;
    top: 2px;
}
.add {
    color: var(--primary);
    position: relative;
    top: -17px;
}
.add::after {
    content: 'Please add your patient here!';
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    top: -16px;
    left: 20px;
    color: var(--primary);
    background: var(--white);
    border: 1px solid var(--primary);
    padding: 5px 10px;
    border-radius: 2px;
    animation: pop 2s infinite;
}

.ok > div {
    margin-left: -1em;
    color: var(--primary);
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
}
.ok > div > svg {
    position: absolute;
    top: 18px;
}
.ok > div > p {
    margin: 0px;
    display: block;
    position: relative;
    font-weight: 500;
    top: 15px;
    left: 40px;
    font-size: 14px;
    color: var(--primary);
    border-radius: 2px;
}
.ok > div > p > span {
    color: var(--dark);
    display: block;
    font-size: 12px;
    opacity: 0.8;
    /* margin-bottom: 5px; */
}

.ok > div > p > span > b {
    padding-left: 2px;
}

.ok p > span {
    margin-bottom: 2px;
}

.address {
    margin: 20px 0 6px 5px;
    color: var(--grey);
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    max-width: 390px;
}

.address > span {
    font-weight: 500;
    padding-left: 2px;
    font-size: 11px;
}

.cause {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--primary);
    font-weight: 500;
}

.cause > span {
    font-size: 13px;
    padding-left: 6px;
    color: var(--red);
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 440px) {
    .Nav {
        padding-bottom: 0px;
        margin-bottom: 20px;
        grid-template-columns: minmax(0, 80%);
        width: 100%;
        margin-top: -12px;
    }

    .alignLeft {
        visibility: hidden;
        width: 100%;
    }

    .cause {
        padding-left: 10px;
        padding-bottom: 4px;
        font-size: 10px;
    }
    .cause > span {
        font-size: 12px;
    }

    .patientDetails {
        margin-top: -3.8em;
    }

    .add {
        top: -44px;
        margin-bottom: -10px;
    }

    .ok {
        width: 90%;
    }

    .ok > div {
        gap: 20px;
    }
    .ok > div > svg {
        font-size: 26px;
    }
    .ok > div > p {
        font-size: 12px;
        text-transform: capitalize;
    }
    .ok > div > p > span {
        color: var(--grey);
        font-size: 10px;
    }
}
