.HistoryPrev {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
}
.HistoryPrev h3 {
    display: inline-block;
    font-size: 16px;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.HistoryPrev h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.toggle::after {
    transform: rotate(180deg);
    top: 6px !important;
}
