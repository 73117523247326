.DropDown {
    position: relative;
}
.DropDown select {
    border: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 10px;
    background: var(--light);
    border-bottom: 2px solid var(--light);
    cursor: pointer;
}
.DropDown select:focus {
    outline: 0;
}
.DropDown select:hover {
    border-bottom: 2px solid var(--primary);
    transition: 200px;
}
/* .DropDown select + span {
    position: absolute;
    width: 0;
    height: 0;
    top: 20px;
    right: 10px;
    border-top: 5px solid var(--grey);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    pointer-events: none;
}

.DropDown select:hover,
.DropDown select:hover option {
    cursor: pointer;
} */
