.DiagnosisPrev {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.DiagnosisPrev .del {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.DiagnosisPrev:hover .del {
    display: block;
}
.DiagnosisPrev h3 {
    display: inline-block;
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.DiagnosisPrev h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.toggle::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.DiagnosisPrev ol {
    margin: 0;
    padding: 0 15px;
}
.DiagnosisPrev ol li {
    font-size: 12px;
    margin: 5px 0;
    text-align: justify;
}

.diagnosis div {
    border: 1px solid var(--light-grey);
    background-color: var(--light-grey);
    margin: 10px 20px 15px 0;
    padding: 10px;
    border-radius: 5px;
}
.diagnosis div h4 {
    margin: 0 0 5px 0;
    padding: 0 0 0 0;
    font-weight: 400;
    display: inline-block;
    color: var(--dark-blue);
    border-bottom: 3px solid var(--light-grey);
}
.diagnosis div p {
    margin-top: 0;
    margin-bottom: 0;
}
