.Dashboard {
    display: grid;
    grid-template-columns: minmax(0, 250px) auto;
    position: relative;
}

.minimize > div:nth-child(2) {
    position: absolute;
    /* animation: left 300ms; */
    left: 0;
    width: 100%;
}
.minimize > div:first-child {
    /* animation: op 300ms; */
    opacity: 0;
}

.maximize {
    position: absolute;
    /* animation: right 300ms; */
    right: inherit;
    width: 100%;
}
h2 {
    color: var(--primary);
}

.Dashboard .mobileBtn {
    display: none;
}

@keyframes right {
    100% {
        right: 0;
    }
    0% {
        right: 250px;
    }
}
@keyframes left {
    0% {
        left: 250px;
    }
    100% {
        left: 0;
    }
}
@keyframes op {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 440px) {
    .Dashboard {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .Dashboard > div:nth-child(3) {
        margin-bottom: -40px;
    }

    .Dashboard .mobileBtn {
        border-top: 1px solid var(--light);
        display: block;
        padding-bottom: 10em;
        background: var(--white);
        z-index: 10;
    }
}
