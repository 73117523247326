.InvestigationPrev {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.InvestigationPrev .del {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.InvestigationPrev:hover .del {
    display: block;
}
.InvestigationPrev h3 {
    display: inline-block;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.InvestigationPrev h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.toggle::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.InvestigationPrev ul {
    list-style-type: none;
    margin: 0 0 0 10px;
    padding: 0;
}
.InvestigationPrev ul li {
    font-size: 12px;
    margin-left: 18px;
    position: relative;
}
.InvestigationPrev ul li::before {
    position: absolute;
    top: 5px;
    left: -16px;
    content: '';
    width: 8px;
    height: 8px;
    background: var(--primary);
}
.InvestigationPrev ul li::after {
    position: absolute;
    top: 3px;
    left: -18px;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid var(--primary);
}
