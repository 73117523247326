.ExamField {
    margin-bottom: 5px;
}
.ExamField p {
    font-size: 12px;
    font-weight: 500;
    color: var(--dark-blue);
}

.wrap {
    display: grid;
    grid-template-columns: 25% 40% 20% 15%;
    gap: 5px;
}
.wrap1 {
    display: grid;
    grid-template-columns: 25% 10% 65%;
    gap: 5px;
}
.wrap2 {
    display: grid;
    grid-template-columns: 25% 20% 55%;
    gap: 5px;
}
.wrap3 {
    display: grid;
    grid-template-columns: 25% 20% 20% 35%;
    gap: 5px;
}
.wrap4 {
    display: grid;
    grid-template-columns: 25% 20% 2% 20% 33%;
    gap: 5px;
}
.wrap4 span {
    position: relative;
    top: 10px;
    color: var(--grey);
}
.wrap5 {
    display: grid;
    grid-template-columns: 25% 20% 5% 20% 10% 20%;
    gap: 5px;
}

input.onExam {
    box-sizing: border-box;
    appearance: none;
    border: none;
    color: var(--dark-blue);
    background: var(--light);
    padding: 0 5px;
    font-size: 12px;
}
input.onExam:focus {
    outline: 0;
}
input.onExam::placeholder {
    font-size: 12px;
}
input.onExam[type='text'] {
    border: none;
    border-bottom: 2px solid var(--grey2);
}
input.onExam[type='text']:hover,
input.onExam[type='text']:valid {
    border-bottom: 2px solid var(--primary);
}

input.onExam[type='number'] {
    border: none;
    border-bottom: 2px solid var(--grey2);
    padding-left: 25px;
}
input.onExam[type='number']:hover,
input.onExam[type='number']:valid {
    border-bottom: 2px solid var(--primary);
}

select.selectExam {
    border: none;
    background: var(--light);
    border-bottom: 2px solid var(--primary);
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 7px;
    width: 100%;
    padding-left: 40%;
    position: relative;
}
select.selectExam:focus {
    outline: 0;
}
select.selectExam > option {
    cursor: pointer;
}
