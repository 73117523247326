.wrapper {
    width: 960px;
    margin: 0 auto;
    box-shadow: 2px 2px 10px var(--grey);
    position: relative;
}
.Generate {
    width: 100%;
    min-height: 270mm;
    background: var(--white);
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.top {
    padding: 10px 0 30px 0;
    margin: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    border-bottom: 1px solid var(--grey);
}
.topLeft > h3 {
    margin-bottom: 5px;
    font-size: 16px;
}
.topLeft > p {
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 12px;
    color: var(--dark-blue);
    font-weight: 300;
}
.OnExamUl {
    list-style-type: none;
    margin: 0;
    padding: 5px 10px;
}
.OnExamUl li {
    font-size: 12px;
    position: relative;
}
.OnExamUl li div {
    position: relative;
}
.OnExamUl li div span {
    position: absolute;
    left: 100px;
}
.OnExamUl li::before {
    content: '';
    position: absolute;
    z-index: 10;
    left: -15px;
    top: 4px;
    width: 7px;
    height: 7px;
    border: 1px solid var(--primary);
}
.OnExamUl li::after {
    content: '';
    position: absolute;
    z-index: 5;
    left: -12px;
    top: 6px;
    width: 7px;
    height: 7px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
}
.topRight {
    text-align: right;
}
.topRight > h3 {
    margin-bottom: 5px;
    font-size: 16px;
}
.topRight > p {
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 12px;
    color: var(--dark-blue);
    font-weight: 300;
}
/* .topRight {
    position: relative;
}
.topRight img {
    position: absolute;
    top: 20px;
    right: 0;
    width: 120px;
} */
.patient {
    padding: 0 0;
    margin: 0 50px;
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 23%) minmax(0, 12%) minmax(0, 14%) minmax(0, 13%);
    font-size: 12px;
    position: relative;
    border-bottom: 1px solid var(--grey);
}
.patient b {
    font-weight: 500;
}

.patient p > span {
    text-transform: capitalize;
}

.date {
    position: absolute;
    right: 0;
}
.middleBody {
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);
    padding: 0 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.leftBody {
    border-right: 1px solid var(--grey);
}
.leftBody h4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
    color: var(--primary);
}
.leftBody p {
    font-size: 12px;
    margin: 3px 10px;
}
.leftBody ol,
.leftBody ul {
    padding-left: 20px;
    margin-top: 5px;
}
.leftBody ol li,
.leftBody ul li {
    font-size: 12px;
}
.leftBody ul li span {
    font-size: 10px;
    font-weight: 400;
}
.leftBody .coMorbidity {
    background: var(--light-grey);
    border-radius: 2px;
    padding: 5px;
    margin-right: 10px;
}
.leftBody .diagnosis {
    background: var(--light-grey);
    border-radius: 2px;
    /* padding: 5px 20px; */
    margin-right: 10px;
}
.leftBody .diagnosis li {
    font-size: 12px;
    padding-left: 10px;
}
.leftBody .diagnosis ol > li:first-child {
    padding-top: 10px;
}
.leftBody .diagnosis ol > li:last-child {
    padding-bottom: 10px;
}
.rightBody {
    padding-left: 30px;
}
.rightBody h4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
    color: var(--primary);
}
.rightBody ol {
    padding-left: 20px;
    margin-top: 5px;
}
.rightBody ol li {
    font-size: 12px;
}
.singleMedicine {
    margin-bottom: 14px;
}

.singleMedicine p {
    font-size: 12px;
    margin: 5px;
}
.singleMedicine p:nth-child(1) {
    font-size: 12px;
    font-weight: 500;
}
.singleMedicine p:nth-child(2) {
    padding-left: 20px;
}
.singleMedicine span {
    font-size: 14px;
    margin-left: 0;
    position: relative;
}
.singleMedicine span::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 6px;
    width: 7px;
    height: 7px;
    background: var(--dark-blue);
    border-radius: 2px;
}
.refer {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
}
.refer {
    font-size: 12px;
    color: var(--dark);
    margin: 0;
}
.refer > span {
    color: var(--primary);
}
.follow {
    font-size: 12px;
    margin: 0;
}
.signature {
    display: grid;
    grid-template-columns: 50% 50%;
}
.signature > div:nth-child(1) > img {
    width: 140px;
    height: 140px;
    margin-left: 100px;
}
.signature > div:nth-child(2) > p {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    margin: 0;
}
.footer {
    color: var(--white);
    background: var(--primary);
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
}
.footer p {
    color: var(--white);
    margin: 0;
    text-align: center;
    font-size: 12px;
    padding: 5px;
}

.advice {
    width: 75%;
}

.logo > img {
    margin-top: 10px;
    width: 180px;
    height: 42px;
    margin-right: -14px;
    opacity: 0.85;
}
