.CoMorbidity h4 {
    margin-bottom: 5px;
    color: var(--dark-blue);
    position: relative;
}
.CoMorbidity h4 > span {
    font-size: 16px;
    position: absolute;
    right: 100px;
}
.CoMorbidity > .wrapper > button {
    display: inline-block;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 20px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--light);
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    transition: 200ms;
}
.CoMorbidity > .wrapper > button:hover {
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
    color: var(--white);
}

.check {
    margin: 0 10px;
    position: relative;
    display: grid;
    grid-template-columns: 35% 20% 45%;
    gap: 2px;
    align-items: baseline;
}
.check p {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-blue);
    position: relative;
}
.check > p > span {
    position: relative;
    top: -10px;
    right: -5px;
    font-size: 16px;
    color: var(--primary);
}
.check > button {
    display: inline-block;
    width: 60px;
    border: 1px solid var(--primary);
    border-radius: 2px;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
}
.deactive {
    color: var(--primary);
    background: var(--light);
}
.check > button:hover,
.active {
    background: var(--primary);
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
}
.check > input {
    font-size: 12px;
    padding: 5px;
    border: 1px solid var(--grey2);
    background: var(--light);
    padding: 10px;
}
.check > input:focus, .check > input:hover{
    outline: 0;
    border: 1px solid var(--secondary);
}
