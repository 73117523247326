.ChiefComplaintsPrev {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.ChiefComplaintsPrev h3 {
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.ChiefComplaintsPrev h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.ChiefComplaintsPrev .del {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.ChiefComplaintsPrev:hover .del {
    display: block;
}

.toggle::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.ChiefComplaintsPrev ul {
    list-style-type: none;
    margin: 5px 0;
    padding: 0 0 0 25px;
}
.ChiefComplaintsPrev ul li {
    font-size: 12px;
    position: relative;
}
.ChiefComplaintsPrev ul li::before {
    position: absolute;
    left: -20px;
    top: 5px;
    content: '';
    width: 10px;
    height: 5px;
    /* border-left: 2px solid var(--primary); */
    border-bottom: 2px solid var(--dark-blue);
    transform: rotate(315deg);
}
/* .ChiefComplaintsPrev ul li span {
    margin-left: 5px;
    font-size: 11px;
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 2px;
    padding: 0 5px;
    display: none;
}
.ChiefComplaintsPrev ul li:hover span {
    display: inline-block;
    cursor: pointer;
} */
