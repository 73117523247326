.Body {
    position: relative;
}
.Body > button {
    position: absolute;
    top: 40vh;
    left: 40%;
    border: none;
    outline: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--white);
    background: var(--primary);
    border-radius: 50px;
    padding: 5px;
    width: 40px;
    height: 40px;
    margin: 0 0 0 -20px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;
}
.Body > button > svg {
    transform: rotate(90deg);
    font-size: 22px;
    line-height: 28px;
}
.Body > button:hover {
    transform: rotate(360deg);
}
.Wrapper {
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);
    height: 100vh;
}
.Wrapper > div {
    overflow-y: auto;
}
.Wrapper > div:first-child {
    border-right: 1px solid var(--light-grey);
    height: 100vh;
    overflow-y: auto;
}
.clearAll {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: var(--white);
    background: var(--primary);
    font-size: 12px;
    padding: 7px 20px 6px;
    border-radius: 50px;
    cursor: pointer;
}

@media (max-width: 440px) {
    .Body {
        overflow-y: auto;
        min-height: 30em;
        margin-top: -10px;
        width: 100%;
    }

    .Wrapper {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-bottom: -2em;
        min-height: 20em;
    }
    .Wrapper > div {
        overflow-y: visible;
    }

    .Body > button {
        display: none;
    }

    .clearAll {
        bottom: 82px;
        right: 14px;
        font-size: 10px;
        padding: 3px 10px 2px;
    }
}
