.TextField {
    overflow: hidden;
}
.TextField > form {
    position: relative;
    margin: 30px 0 0 0;
    max-width: 560px;
    min-width: 400px;
}
.TextField > form > label {
    position: absolute;
    left: 5px;
    top: 10px;
    padding: 0 3px;
    color: var(--grey);
    font-size: 14px;
    transition: 150ms;
    pointer-events: none;
}
.TextField > form > textarea {
    box-sizing: border-box;
    padding: 10px;
    border: none;
    background: var(--light);
    color: var(--dark-blue);
    resize: none;
    width: 100%;
    position: relative;
    border-radius: 4px;
}
.TextField textarea + span {
    height: 2px;
    width: 100%;
    background: var(--grey2);
    position: absolute;
    bottom: 5px;
    left: 0;
}
.TextField textarea + span::after {
    position: absolute;
    left: -100%;
    content: '';
    height: 3px;
    width: 100%;
    background: var(--primary);
    transition: 150ms;
}
.TextField textarea:focus {
    outline: 0;
}
.TextField textarea:focus + span::after,
.TextField textarea:valid + span::after {
    left: 0;
}
.TextField textarea:focus ~ label:nth-of-type(1),
.TextField textarea:valid ~ label:nth-of-type(1) {
    top: -30px;
    color: var(--primary);
}

@media (max-width: 440px) {
    .TextField > form > label {
        font-size: 12px;
    }
}
