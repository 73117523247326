.InvestigationInp {
    padding: 30px;
}

.InvestigationInp > button {
    color: var(--white);
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    padding: 7px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}
.InvestigationInp > button:hover {
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

@media (max-width: 440px) {
    .InvestigationInp {
        padding: 10px;
        position: relative;
    }

    .InvestigationInp button {
        display: block;
        margin: 10px auto;
        font-size: 12px;
        padding: 6px 30px;
    }
}
