.wrapper {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.box {
    position: relative;
    background: var(--white);
    width: 700px;
    box-shadow: 0 5px 20px var(--grey2);
    border-radius: 5px;
    text-align: center;
}
.formWrapper > select,
.formWrapper > input,
.formWrapper > textarea,
.formWrapper > button {
    width: 70%;
    margin-left: 15%;
    display: block;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid var(--light);
}
.formWrapper > select:focus,
.formWrapper > input:focus,
.formWrapper > textarea:focus,
.formWrapper > button:focus {
    outline: 0;
}
.formWrapper > select > option {
    color: grey;
    padding: 10px;
}

.formWrapper > button {
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
    margin-bottom: 20px;
}

.formWrapper > button:hover {
    opacity: 0.9;
    transition: 200ms;
}

.formWrapper > select {
    cursor: pointer;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    transition: all 200ms;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
    color: var(--red);
    cursor: pointer;
}

.close:hover {
    opacity: 0.8;
    transition: 0.5s;
}

@media (max-width: 440px) {
    .box {
        width: 96%;
    }

    .box > h2 {
        font-size: 16px;
    }

    .formWrapper > select,
    .formWrapper > input,
    .formWrapper > textarea,
    .formWrapper > button {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
        font-size: 12px;
    }
}
