.ChiefComplaintsInp {
    padding: 15px 80px 15px 30px;
    position: relative;
}
.ChiefComplaintsInp button {
    display: block;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
}
.ChiefComplaintsInp button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

@media (max-width: 440px) {
    .ChiefComplaintsInp {
        padding: 10px;
        position: relative;
    }

    .ChiefComplaintsInp button {
        display: block;
        margin: 10px auto;
        font-size: 12px;
        padding: 6px 30px;
    }
}
