.DiagnosisInp {
    padding: 30px;
}
.DiagnosisInp button {
    display: block;
    border: none;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    border-radius: 4px;
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
    margin-bottom: 30px;
}
.DiagnosisInp button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}
