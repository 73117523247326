.InputNumber {
    position: relative;
}

.InputNumber .wrap {
    display: flex;
    justify-content: center;
}
.InputNumber > .wrap > input[type='number'] {
    border: none;
    border-radius: 4px;
    outline: 0;
    box-sizing: border-box;
    padding: 20px 10px 10px 10px;
    width: 100%;
    background: var(--light);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
.InputNumber input[type='number']::-webkit-outer-spin-button,
.InputNumber input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.InputNumber input + label {
    position: absolute;
    font-size: 12px;
    top: 16px;
    color: var(--grey);
    pointer-events: none;
    transition: 200ms;
}
.InputNumber input:focus + label,
.InputNumber input:valid + label {
    font-size: 10px;
    top: 16px;
    color: var(--primary);
    opacity: 0;
}
