@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root {
    --primary: #457dc3;
    --secondary: #2e9eff;
    --dark: #333;
    --dark-blue: #23395d;
    --dark-grey: #555;
    --grey: #7987a1;
    --grey2: #aebacf;
    --light-grey: #dee3ec;
    --light: #f5f5f5;
    --light-low: #f8f8f8;
    --white: #fff;
    --red: #ef5455;
    --green: #7ab97a;
}
* {
    font-family: Poppins;
}
body {
    margin: 0;
}
h1,
h2,
h3 {
    color: var(--primary);
}
h4,
h5 {
    color: var(--dark);
}
p {
    color: var(--dark);
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: var(--light-grey);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--grey);
}
