.PreviewMedicine {
    position: relative;
}
.PreviewMedicine h4 {
    margin-left: 30px;
    color: var(--primary);
}

.PreviewMedicine .del {
    position: absolute;
    top: 5px;
    right: 200px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--red);
    background: transparent;
    font-size: 16px;
    cursor: pointer;
}
.PreviewMedicine:hover .del {
    display: block;
}

.medicine {
    /* border: 1px solid var(--primary); */
    box-shadow: 2px 1px 5px var(--grey2);
    border-radius: 4px;
    padding: 5px 10px;
    min-height: 100px;
    box-sizing: border-box;
    margin-left: 25px;
    margin-bottom: 20px;
    width: 70%;
    transition: 200ms;
    background: var(--white);
    border-left: 4px solid var(--primary);
}
.medicine:hover {
    box-shadow: 2px 1px 10px var(--grey2);
}
.medicine p {
    font-size: 12px;
    margin: 2px 0;
    color: var(--dark);
    padding-left: 6px;
    padding-top: 2px;
}
.medicine p span {
    margin: 0 10px;
}
.medicine p:first-child {
    font-weight: 500;
    margin: 6px 0;
}
.medicine > p:nth-child(2),
.medicine > p:nth-child(3) {
    margin-left: 30px;
}
.btn {
    position: relative;
    float: right;
    right: 10px;
    top: -3.2em;
    opacity: 0.95;
}

.btn button {
    position: relative;
    display: block;
    font-size: 11px;
    padding: 4px 6px 2px;
    min-width: 70px;
    margin-top: 5px;
    cursor: pointer;
    border: none;
    color: var(--white);
    background-color: var(--secondary);
    border-radius: 2px;
}
.btn button:nth-child(1) {
    background: var(--red);
    color: var(--white);
}

.btn button:hover {
    opacity: 0.8;
    cursor: pointer;
    transition: 200ms;
}

.btn .mob {
    display: none;
}

@media (max-width: 440px) {
    .PreviewMedicine {
        position: relative;
        padding: 0 6px;
        margin-top: 4em;
    }

    .PreviewMedicine > h4 {
        display: none;
    }

    .list {
        height: 20em;
        overflow: auto;
        padding-top: 10px;
    }

    .medicine {
        margin-left: 0px;
        width: 100%;
        background: transparent;
        border-left: 4px solid var(--primary);
        position: relative;
    }

    .medicine p {
        font-size: 12px;
        margin: 2px 0;
        color: var(--dark);
        padding-left: 6px;
        padding-top: 2px;
    }

    .PreviewMedicine .del {
        position: absolute;
        top: 0px;
        right: 10px;
        display: none;
        border: none;
        outline: 0;
        border-radius: 50%;
        color: var(--white);
        background: var(--red);
        font-size: 12px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .btn button {
        display: none;
    }

    .btn {
        right: 6px;
        top: -2px;
    }

    .btn .mob {
        display: block;
        background-color: transparent;
        color: var(--red);
        padding: 4px 8px;
        min-width: 20px;
        border-radius: 50px;
        border: none;
        font-size: 18px;
        font-weight: 500;
        margin-top: -3.5em;
        float: right;
        right: -14px;
    }
}
