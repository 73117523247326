.NextFollowUpInpOut {
    padding: 30px;
    width: 80%;
    gap: 15px;
}
.NextFollowUpInpOut .days {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.NextFollowUpInpOut button {
    display: inline-block;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
    min-width: 100px;
}
.NextFollowUpInpOut button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

.nextP {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: 5px 15px;
    border-radius: 4px;
    margin-top: 30px;
}
