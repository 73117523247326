.OnExaminationInp {
    padding: 30px;
}
.btnWrapper {
    width: 50%;
    margin: 0 auto;
}
.btnWrapper button {
    border: 2px solid var(--primary);
    border-radius: 2px;
    background: var(--primary);
    color: var(--white);
    cursor: pointer;
    font-size: 12px;
    padding: 7px 25px;
    margin: 30px 5px;
}
.btnWrapper button:nth-child(1) {
    background: var(--white);
    color: var(--primary);
    border: 2px solid var(--primary);
}
