.Landing {
    width: 100vw;
    height: 100vh;
    background: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Wrap {
    display: inline-block;
    background: var(--secondary);
    border-radius: 5px;
    padding: 30px 80px;
}
.Wrap > h2,
.Wrap > h1 {
    color: var(--white);
    font-weight: 500;
}
.Wrap > h1 {
    font-size: 60px;
    margin: 0;
}
.Wrap > h2 {
    margin: 10px;
    font-size: 30px;
}

@media (max-width: 440px) {
    .Wrap {
        padding: 30px 40px;
        width: 70%;
    }
    .Wrap > h1 {
        font-size: 30px;
        margin: 0;
        text-align: center;
    }
    .Wrap > h2 {
        margin: 10px;
        font-size: 26px;
        text-align: center;
        margin-left: -10px;
    }
}
