.Sidebar {
    height: 100vh;
    border-right: 1px solid var(--light-grey);
}
.Sidebar > a > button {
    border: none;
    border-radius: 50px;
    background: var(--primary);
    color: var(--white);
    padding: 5px 25px;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 25px 0 10px 32px;
    cursor: pointer;
    transition: 200ms;
}
.Sidebar > a {
    color: var(--white);
    text-decoration: none;
}
.Sidebar > a > button:hover {
    transform: scale(1.1);
    background: var(--secondary);
}
.epLogo img {
    display: block;
    margin: 20px auto;
}
.docImg {
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
    border: 4px solid var(--light-grey);
}
.doc h3 {
    text-align: center;
    color: var(--primary);
    margin-bottom: 0;
    font-size: 16px;
}
.doc p {
    text-align: center;
    margin-top: 5px;
    color: var(--grey);
    font-size: 12px;
}
.doc hr {
    background-color: var(--light-grey);
    border: none;
    height: 1px;
    width: 80%;
    margin-left: 10%;
}

.nav {
    list-style: none;
    padding: 0;
    overflow: hidden;
}

.mobileNav {
    display: none;
}

.nav li {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 0 6px 80px;
    color: var(--dark-blue);
    transition: 100ms;
    position: relative;
}
.nav li svg {
    position: absolute;
    top: 10px;
    left: 50px;
    font-size: 12px;
}

.nav li span {
    display: none;
}

.active {
    /* background-color: var(--light-grey); */
    background-image: linear-gradient(to right, var(--primary) 0%, var(--white) 150%);
    color: var(--white) !important;
    cursor: pointer;
    transform: scale(1.1);
    /* color: var(--primary) !important; */
}

.active:hover {
    opacity: 0.9;
}

.nav li:hover {
    background-color: var(--light-low);
    color: var(--primary);
    cursor: pointer;
    transform: scale(1.1);
}

@media (max-width: 440px) {
    .Sidebar {
        width: 99.5%;
        height: 100%;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--light);
        box-shadow: 2px 4px 8px var(--light);
        width: 100%;
        z-index: 100;
        background-color: var(--white);
    }

    .epLogo img {
        display: block;
        margin: 10px;
        width: 160px;
    }

    .doc > * {
        display: none;
    }

    .doc > div {
        display: block;
        width: 36px;
        height: 36px;
        margin: 10px;
        border: 2px solid var(--light-grey);
        margin-right: 16px;
        z-index: 500;
    }

    .nav {
        display: none;
    }

    .mobileHide {
        display: none;
    }
}
