.HistoryPrev {
    position: relative;
}
.HistoryPrev .del {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.HistoryPrev:hover .del {
    display: block;
}

.HistoryPrev .historyChildH {
    margin: 10px 0 0 20px;
    color: var(--dark-blue);
    display: inline-block;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    font-size: 14px;
}
.historyChildH::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid var(--dark-blue);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    right: -17px;
    top: 12px;
    display: none;
}
.historyChildH::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--dark-blue);
    border-left: 5px solid transparent;
    position: absolute;
    right: -17px;
    top: 6px;
}
.toggle::before {
    display: block;
}
.toggle::after {
    display: none;
}
.HistoryPrev .historyli {
    list-style-type: none;
    margin: 10px 0 0 20px;
    padding: 0 0 0 25px;
}
.HistoryPrev .historyli li {
    font-size: 12px;
    position: relative;
}
.HistoryPrev .historyli li::before {
    position: absolute;
    left: -15px;
    top: -12px;
    content: '';
    width: 10px;
    height: 100%;
    border-left: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
}

.combrth {
    color: var(--dark-blue);
    margin: 20px 0 5px 20px;
}
.combrt {
    list-style-type: none;
    margin-top: 0;
    margin-left: 30px;
    padding: 0;
}
.combrt li {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    color: var(--primary);
    position: relative;
}
.combrt li span {
    font-weight: 400;
    color: var(--dark-blue);
    position: relative;
    margin-left: 10px;
}
.combrt li span::before {
    content: '+ ';
    color: var(--primary);
    position: absolute;
    top: -12px;
    left: -12px;
}
