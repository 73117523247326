.SubmitEP > a > button,
.SubmitEP > button {
    border: none;
    border-radius: 50px;
    background: var(--primary);
    color: var(--white);
    padding: 6px 24px 5px;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 25px auto 10px auto;
    cursor: pointer;
    transition: 200ms;
}
.SubmitEP > a,
.SubmitEP > button {
    color: var(--white);
    text-decoration: none;
}
.SubmitEP > a > button:hover,
.SubmitEP > button:hover {
    transform: scale(1.05);
}
.SubmitEP > button :nth-child(2):hover {
    background: var(--green);
}

.SubmitEP > .viewBtn {
    background: var(--green);
}
.SubmitEP > .viewBtn > a {
    color: var(--white);
    text-decoration: none;
    text-align: center;
}

@media (max-width: 440px) {
    .SubmitEP {
        bottom: 0;
    }

    .SubmitEP > .viewBtn {
        background: var(--green);
        margin-top: 10px;
    }
}
