.OnExaminationPrev {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.OnExaminationPrev .del {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.OnExaminationPrev:hover .del {
    display: block;
}

.OnExaminationPrev h3 {
    font-size: 16px;
    display: inline-block;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.OnExaminationPrev h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.toggle::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.OnExaminationPrev ul {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 0;
}
.OnExaminationPrev ul li {
    font-size: 12px;
    margin-left: 25px;
    margin-bottom: 4px;
    position: relative;
    color: var(--dark);
}
.OnExaminationPrev ul li::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid var(--primary);
    background: var(--white);
    top: 3px;
    left: -17px;
    z-index: 100;
}

.OnExaminationPrev ul li::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--primary);
    top: 6px;
    left: -14px;
}
.OnExaminationPrev ul li span {
    position: absolute;
    left: 100px;
    font-weight: 400;
    color: var(--dark-blue);
}
