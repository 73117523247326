.EpPDF {
    position: relative;
    padding: 60px 0;
    background: var(--light-grey);
}
.EpPDF .btnGroup {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: linear-gradient(var(--light), var(--white), var(--light));
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 20px var(--grey);
    padding: 4px 0;
}
.btnGroup > a,
.btnGroup > button {
    color: var(--primary);
    text-decoration: none;
    font-size: 14px;
    padding: 7px 20px;
    background: transparent;
    border: none;
    outline: 0;
    cursor: pointer;
    border-right: 1px solid var(--light-grey);
}
.btnGroup button:last-child {
    border-right: none;
}
.btnGroup > a:hover,
.btnGroup > button:hover {
    color: var(--secondary);
}

.warning {
    text-align: center;
    color: var(--red);
    font-size: 10px;
    margin-top: -10px;
    margin-bottom: 6px;
    font-weight: 500;
}

.warning > span {
    font-weight: 600;
}

@media (max-width: 440px) {
    .EpPDF {
        padding-bottom: 10px;
    }
    .EpPDF .btnGroup {
        top: 10px;
        width: 90%;
    }

    .btnGroup > a,
    .btnGroup > button {
        font-size: 10px;
    }

    .btnGroup > button:nth-child(3) {
        background-color: var(--primary);
        color: var(--white);
        border-radius: 4px;
        display: inline-block;
        margin-left: 4px;
    }

    .btnGroup > button:nth-child(3):hover {
        opacity: 0.9;
        transition: 250ms;
    }

    .pdf {
        width: 90%;
        overflow: auto;
        height: 70vh;
        margin: 0 auto;
        background-color: var(--light-grey);
        box-shadow: 4px 8px 16px var(--light);
        border-radius: 4px;
        border: 1px solid var(--grey2);
        padding: 10px;
    }

    .warning {
        font-size: 9px;
        margin-top: -10px;
        padding: 10px;
    }
}
