.InputSection > h3 {
    margin: 0;
    padding: 0;
    color: var(--primary);
    font-size: 16px;
    padding-left: 30px;
    position: relative;
}
.InputSection > h3::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 15px solid var(--primary);
    border-right: 12px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 12px solid var(--primary);
    z-index: -100;
}

@media (max-width: 440px) {
    .InputSection {
        margin-top: 2px;
        width: 100%;
    }
}
