.Patient {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: initial;
}
.Patient::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 110vh;
    background: var(--dark-blue);
    opacity: 0.3;
    z-index: 150;
}

.Patient form {
    position: absolute;
    top: 30%;
    width: 40%;
    z-index: 2000;
    background: var(--white);
    transform: translate(70%, -35%);
    padding: 40px 20px;
    border-radius: 5px;
    box-shadow: 1px 5px 15px var(--dark);
    transition: 300ms;
    z-index: 151;
}
.Patient > form > h2 {
    text-align: center;
}
.Patient > form > span {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--white);
    background: var(--grey2);
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: 2px;
}
.Patient > form > span:hover {
    background: var(--red);
}

.close {
    color: var(--red);
    position: absolute;
    top: 8.2em;
    left: 4.8em;
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
}

.close:hover {
    opacity: 0.8;
    transition: 200ms;
    cursor: pointer;
}

.Wrapper {
    padding: 0 50px;
    z-index: 153;
}
.Wrapper input {
    padding: 7px 10px;
    font-size: 14px;
    margin: 7px 0;
    border: 1px solid var(--light);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background: var(--light);
    color: var(--dark);
    z-index: 154;
}
.Wrapper input:focus {
    border: 1px solid var(--primary);
    outline: 0;
}
.Wrapper > input:nth-child(1) {
    border: 1px solid var(--primary);
    transform: scale(1.05);
    transition: 300ms;
}
.Wrapper > button {
    display: block;
    width: 100%;
    background: var(--primary);
    cursor: pointer;
    border: none;
    color: var(--white);
    padding: 10px;
    border-radius: 2px;
    margin-top: 10px;
}
.Wrapper > button:focus {
    outline: 0;
}

.Two {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    gap: 10px;
}
.Wrapper select {
    border-radius: 4px;
    border: 1px solid var(--light);
    background: var(--light);
    padding: 7px 10px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 7px 0;
    color: var(--dark);
}
.Wrapper select:focus {
    outline: 0;
    border: 1px solid var(--primary);
}

.clear {
    position: absolute;
    top: 9.3em;
    right: 66px;
    background-color: var(--red);
    color: var(--white);
    padding: 0px 10px 2px;
    border-radius: 4px;
    font-size: 14px;
}

.clear:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 200ms;
}

@media (max-width: 440px) {
    .Patient {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 100;
    }

    .Patient form {
        top: 30%;
        width: 80%;
        z-index: 2000;
        transform: translate(2%, -35%);
        padding: 40px 28px;
        padding-bottom: 30px;
    }

    .Patient > form > h2 {
        text-align: center;
        font-size: 18px;
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .Patient > form > span {
        background: var(--red);
        top: 20px;
    }

    .close {
        color: var(--red);
        position: absolute;
        top: 5em;
        left: 2em;
        font-weight: 500;
        font-size: 12px;
        text-decoration: underline;
    }

    .Wrapper {
        padding: 0px;
    }

    .Wrapper input {
        font-size: 12px;
    }

    .Wrapper select {
        font-size: 12px;
    }

    .clear {
        position: absolute;
        right: 30px;
        top: 7.6em;
        background-color: var(--red);
        color: var(--white);
        padding: 0px 10px 2px;
        border-radius: 4px;
        font-size: 12px;
    }
}
