.mobileNav {
    display: none;
    position: fixed;
    background-color: var(--white);
    height: 4.5em;
    width: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    z-index: 100;
    box-shadow: 0px 2px 4px var(--light-grey), 0px -4px 20px var(--light);
}

.mobileNav > div:last-child {
    position: relative;
}

.navigation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.navigation ul {
    display: flex;
    width: 380px;
    margin-left: -44px;
}

.navigation ul li {
    position: relative;
    list-style: none;
    width: 120px;
    z-index: 1;
}

.navigation ul li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: 500;
    color: var(--grey);
    text-decoration: none;
}

.navigation ul li .icon {
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.2em;
    text-align: center;
    transition: 0.5s;
    transform: translateY(-10px);
}

.navigation ul li.active .icon {
    transform: translateY(-12px);
    color: var(--primary);
    transition: 0.5s;
}

.navigation ul li:nth-child(3).active .icon {
    transform: translateY(-19px);
    color: var(--primary);
    transition: 0.5s;
}

.navigation ul li.active .icon > img {
    width: 24px;
    height: 24px;
}

.navigation ul li .text {
    position: absolute;
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 1;
    transform: translateY(10px);
    color: var(--grey);
}

.navigation ul li.active .text {
    margin-top: 10px;
    opacity: 1;
    transform: translateY(8px);
    color: var(--primary);
    transition: 0.5s;
    font-weight: 500;
}

.indicator {
    position: absolute;
    top: 0%;
    width: 48px;
    height: 48px;
    background: var(--white);
    border-radius: 50%;
    border: 4px solid var(--light-low);
    margin-left: 1.6em;
}

.indicator::before {
    content: '';
    position: absolute;
    top: 49%;
    left: -20px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 0 0 rgb(245, 241, 242);
}

.indicator::after {
    content: '';
    position: absolute;
    top: 49%;
    right: -20px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -2px 0 0 rgb(243, 245, 241);
}

.navigation ul li:nth-child(1).active ~ .indicator {
    transform: translateX(calc(114px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
    transform: translateX(calc(120px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
    transform: translateX(calc(68.2px * 2));
}

@media (max-width: 440px) {
    .mobileNav {
        display: flex;
    }
}
