.SuggestionMedicine {
    position: relative;
}
.SuggestionMedicine ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    background: var(--white);
    box-shadow: 1px 5px 10px var(--light-grey);
    border: 1px solid var(--light-grey);
    border-radius: 2px;
    position: absolute;
    z-index: 1000;
    top: -7px;
    min-height: 20px;
    max-height: 160px;
    overflow-y: scroll;
}
.SuggestionMedicine ul li {
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--grey);
}
.SuggestionMedicine ul li:hover {
    background: var(--light);
}
