@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap);
.AuthCheck_AuthCheck__3FD0l {
    height: 100vh;
    width: 100vw;
    background: var(--primary);
}

.EpPDF_EpPDF__1dfuq {
    position: relative;
    padding: 60px 0;
    background: var(--light-grey);
}
.EpPDF_EpPDF__1dfuq .EpPDF_btnGroup__3HXFk {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: linear-gradient(var(--light), var(--white), var(--light));
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 20px var(--grey);
    padding: 4px 0;
}
.EpPDF_btnGroup__3HXFk > a,
.EpPDF_btnGroup__3HXFk > button {
    color: var(--primary);
    text-decoration: none;
    font-size: 14px;
    padding: 7px 20px;
    background: transparent;
    border: none;
    outline: 0;
    cursor: pointer;
    border-right: 1px solid var(--light-grey);
}
.EpPDF_btnGroup__3HXFk button:last-child {
    border-right: none;
}
.EpPDF_btnGroup__3HXFk > a:hover,
.EpPDF_btnGroup__3HXFk > button:hover {
    color: var(--secondary);
}

.EpPDF_warning__2K2pr {
    text-align: center;
    color: var(--red);
    font-size: 10px;
    margin-top: -10px;
    margin-bottom: 6px;
    font-weight: 500;
}

.EpPDF_warning__2K2pr > span {
    font-weight: 600;
}

@media (max-width: 440px) {
    .EpPDF_EpPDF__1dfuq {
        padding-bottom: 10px;
    }
    .EpPDF_EpPDF__1dfuq .EpPDF_btnGroup__3HXFk {
        top: 10px;
        width: 90%;
    }

    .EpPDF_btnGroup__3HXFk > a,
    .EpPDF_btnGroup__3HXFk > button {
        font-size: 10px;
    }

    .EpPDF_btnGroup__3HXFk > button:nth-child(3) {
        background-color: var(--primary);
        color: var(--white);
        border-radius: 4px;
        display: inline-block;
        margin-left: 4px;
    }

    .EpPDF_btnGroup__3HXFk > button:nth-child(3):hover {
        opacity: 0.9;
        transition: 250ms;
    }

    .EpPDF_pdf__102kQ {
        width: 90%;
        overflow: auto;
        height: 70vh;
        margin: 0 auto;
        background-color: var(--light-grey);
        box-shadow: 4px 8px 16px var(--light);
        border-radius: 4px;
        border: 1px solid var(--grey2);
        padding: 10px;
    }

    .EpPDF_warning__2K2pr {
        font-size: 9px;
        margin-top: -10px;
        padding: 10px;
    }
}

.Generate_wrapper__33wLm {
    width: 960px;
    margin: 0 auto;
    box-shadow: 2px 2px 10px var(--grey);
    position: relative;
}
.Generate_Generate__3UnqN {
    width: 100%;
    min-height: 270mm;
    background: var(--white);
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.Generate_top__XlrNY {
    padding: 10px 0 30px 0;
    margin: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
    border-bottom: 1px solid var(--grey);
}
.Generate_topLeft__2x3QH > h3 {
    margin-bottom: 5px;
    font-size: 16px;
}
.Generate_topLeft__2x3QH > p {
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 12px;
    color: var(--dark-blue);
    font-weight: 300;
}
.Generate_OnExamUl__2bn5H {
    list-style-type: none;
    margin: 0;
    padding: 5px 10px;
}
.Generate_OnExamUl__2bn5H li {
    font-size: 12px;
    position: relative;
}
.Generate_OnExamUl__2bn5H li div {
    position: relative;
}
.Generate_OnExamUl__2bn5H li div span {
    position: absolute;
    left: 100px;
}
.Generate_OnExamUl__2bn5H li::before {
    content: '';
    position: absolute;
    z-index: 10;
    left: -15px;
    top: 4px;
    width: 7px;
    height: 7px;
    border: 1px solid var(--primary);
}
.Generate_OnExamUl__2bn5H li::after {
    content: '';
    position: absolute;
    z-index: 5;
    left: -12px;
    top: 6px;
    width: 7px;
    height: 7px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
}
.Generate_topRight__39wRN {
    text-align: right;
}
.Generate_topRight__39wRN > h3 {
    margin-bottom: 5px;
    font-size: 16px;
}
.Generate_topRight__39wRN > p {
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 12px;
    color: var(--dark-blue);
    font-weight: 300;
}
/* .topRight {
    position: relative;
}
.topRight img {
    position: absolute;
    top: 20px;
    right: 0;
    width: 120px;
} */
.Generate_patient__2VO-E {
    padding: 0 0;
    margin: 0 50px;
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 23%) minmax(0, 12%) minmax(0, 14%) minmax(0, 13%);
    font-size: 12px;
    position: relative;
    border-bottom: 1px solid var(--grey);
}
.Generate_patient__2VO-E b {
    font-weight: 500;
}

.Generate_patient__2VO-E p > span {
    text-transform: capitalize;
}

.Generate_date__2UxzT {
    position: absolute;
    right: 0;
}
.Generate_middleBody__3Qn8m {
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);
    padding: 0 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.Generate_leftBody__VNLXL {
    border-right: 1px solid var(--grey);
}
.Generate_leftBody__VNLXL h4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
    color: var(--primary);
}
.Generate_leftBody__VNLXL p {
    font-size: 12px;
    margin: 3px 10px;
}
.Generate_leftBody__VNLXL ol,
.Generate_leftBody__VNLXL ul {
    padding-left: 20px;
    margin-top: 5px;
}
.Generate_leftBody__VNLXL ol li,
.Generate_leftBody__VNLXL ul li {
    font-size: 12px;
}
.Generate_leftBody__VNLXL ul li span {
    font-size: 10px;
    font-weight: 400;
}
.Generate_leftBody__VNLXL .Generate_coMorbidity__2lSsd {
    background: var(--light-grey);
    border-radius: 2px;
    padding: 5px;
    margin-right: 10px;
}
.Generate_leftBody__VNLXL .Generate_diagnosis__LgvRq {
    background: var(--light-grey);
    border-radius: 2px;
    /* padding: 5px 20px; */
    margin-right: 10px;
}
.Generate_leftBody__VNLXL .Generate_diagnosis__LgvRq li {
    font-size: 12px;
    padding-left: 10px;
}
.Generate_leftBody__VNLXL .Generate_diagnosis__LgvRq ol > li:first-child {
    padding-top: 10px;
}
.Generate_leftBody__VNLXL .Generate_diagnosis__LgvRq ol > li:last-child {
    padding-bottom: 10px;
}
.Generate_rightBody__2ORfT {
    padding-left: 30px;
}
.Generate_rightBody__2ORfT h4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
    color: var(--primary);
}
.Generate_rightBody__2ORfT ol {
    padding-left: 20px;
    margin-top: 5px;
}
.Generate_rightBody__2ORfT ol li {
    font-size: 12px;
}
.Generate_singleMedicine__2JjcU {
    margin-bottom: 14px;
}

.Generate_singleMedicine__2JjcU p {
    font-size: 12px;
    margin: 5px;
}
.Generate_singleMedicine__2JjcU p:nth-child(1) {
    font-size: 12px;
    font-weight: 500;
}
.Generate_singleMedicine__2JjcU p:nth-child(2) {
    padding-left: 20px;
}
.Generate_singleMedicine__2JjcU span {
    font-size: 14px;
    margin-left: 0;
    position: relative;
}
.Generate_singleMedicine__2JjcU span::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 6px;
    width: 7px;
    height: 7px;
    background: var(--dark-blue);
    border-radius: 2px;
}
.Generate_refer__2Jl-w {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
}
.Generate_refer__2Jl-w {
    font-size: 12px;
    color: var(--dark);
    margin: 0;
}
.Generate_refer__2Jl-w > span {
    color: var(--primary);
}
.Generate_follow__2u55C {
    font-size: 12px;
    margin: 0;
}
.Generate_signature__2zc3e {
    display: grid;
    grid-template-columns: 50% 50%;
}
.Generate_signature__2zc3e > div:nth-child(1) > img {
    width: 140px;
    height: 140px;
    margin-left: 100px;
}
.Generate_signature__2zc3e > div:nth-child(2) > p {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    margin: 0;
}
.Generate_footer__ysi-W {
    color: var(--white);
    background: var(--primary);
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
}
.Generate_footer__ysi-W p {
    color: var(--white);
    margin: 0;
    text-align: center;
    font-size: 12px;
    padding: 5px;
}

.Generate_advice__2K3ZV {
    width: 75%;
}

.Generate_logo__3JWil > img {
    margin-top: 10px;
    width: 180px;
    height: 42px;
    margin-right: -14px;
    opacity: 0.85;
}

.HeaderUpdate_wrapper__3HzG8 {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.HeaderUpdate_box__D3Wbq {
    position: relative;
    background: var(--white);
    width: 700px;
    box-shadow: 0 5px 20px var(--grey2);
    border-radius: 5px;
    text-align: center;
}
.HeaderUpdate_formWrapper__2fC8J > select,
.HeaderUpdate_formWrapper__2fC8J > input,
.HeaderUpdate_formWrapper__2fC8J > textarea,
.HeaderUpdate_formWrapper__2fC8J > button {
    width: 70%;
    margin-left: 15%;
    display: block;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid var(--light);
}
.HeaderUpdate_formWrapper__2fC8J > select:focus,
.HeaderUpdate_formWrapper__2fC8J > input:focus,
.HeaderUpdate_formWrapper__2fC8J > textarea:focus,
.HeaderUpdate_formWrapper__2fC8J > button:focus {
    outline: 0;
}
.HeaderUpdate_formWrapper__2fC8J > select > option {
    color: grey;
    padding: 10px;
}

.HeaderUpdate_formWrapper__2fC8J > button {
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
    margin-bottom: 20px;
}

.HeaderUpdate_formWrapper__2fC8J > button:hover {
    opacity: 0.9;
    transition: 200ms;
}

.HeaderUpdate_formWrapper__2fC8J > select {
    cursor: pointer;
}

.HeaderUpdate_close__2gzOh {
    position: absolute;
    top: 10px;
    right: 20px;
    transition: all 200ms;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
    color: var(--red);
    cursor: pointer;
}

.HeaderUpdate_close__2gzOh:hover {
    opacity: 0.8;
    transition: 0.5s;
}

@media (max-width: 440px) {
    .HeaderUpdate_box__D3Wbq {
        width: 96%;
    }

    .HeaderUpdate_box__D3Wbq > h2 {
        font-size: 16px;
    }

    .HeaderUpdate_formWrapper__2fC8J > select,
    .HeaderUpdate_formWrapper__2fC8J > input,
    .HeaderUpdate_formWrapper__2fC8J > textarea,
    .HeaderUpdate_formWrapper__2fC8J > button {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
        font-size: 12px;
    }
}

.SubmitEP_SubmitEP__31phy > a > button,
.SubmitEP_SubmitEP__31phy > button {
    border: none;
    border-radius: 50px;
    background: var(--primary);
    color: var(--white);
    padding: 6px 24px 5px;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 25px auto 10px auto;
    cursor: pointer;
    transition: 200ms;
}
.SubmitEP_SubmitEP__31phy > a,
.SubmitEP_SubmitEP__31phy > button {
    color: var(--white);
    text-decoration: none;
}
.SubmitEP_SubmitEP__31phy > a > button:hover,
.SubmitEP_SubmitEP__31phy > button:hover {
    transform: scale(1.05);
}
.SubmitEP_SubmitEP__31phy > button :nth-child(2):hover {
    background: var(--green);
}

.SubmitEP_SubmitEP__31phy > .SubmitEP_viewBtn__1iG4z {
    background: var(--green);
}
.SubmitEP_SubmitEP__31phy > .SubmitEP_viewBtn__1iG4z > a {
    color: var(--white);
    text-decoration: none;
    text-align: center;
}

@media (max-width: 440px) {
    .SubmitEP_SubmitEP__31phy {
        bottom: 0;
    }

    .SubmitEP_SubmitEP__31phy > .SubmitEP_viewBtn__1iG4z {
        background: var(--green);
        margin-top: 10px;
    }
}

.Suggestion_Suggestion__37yGf {
    position: relative;
}
.Suggestion_Suggestion__37yGf ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    background: var(--white);
    box-shadow: 1px 5px 10px var(--light-grey);
    border: 1px solid var(--light-grey);
    border-radius: 2px;
    position: absolute;
    z-index: 1000;
    top: -15px;
    min-height: 20px;
    max-height: 316px;
    overflow-y: scroll;
}
.Suggestion_Suggestion__37yGf ul li {
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--grey);
}
.Suggestion_Suggestion__37yGf ul li:hover {
    background: var(--light);
}

.TextField_TextField__2_w6W {
    overflow: hidden;
}
.TextField_TextField__2_w6W > form {
    position: relative;
    margin: 30px 0 0 0;
    max-width: 560px;
    min-width: 400px;
}
.TextField_TextField__2_w6W > form > label {
    position: absolute;
    left: 5px;
    top: 10px;
    padding: 0 3px;
    color: var(--grey);
    font-size: 14px;
    transition: 150ms;
    pointer-events: none;
}
.TextField_TextField__2_w6W > form > textarea {
    box-sizing: border-box;
    padding: 10px;
    border: none;
    background: var(--light);
    color: var(--dark-blue);
    resize: none;
    width: 100%;
    position: relative;
    border-radius: 4px;
}
.TextField_TextField__2_w6W textarea + span {
    height: 2px;
    width: 100%;
    background: var(--grey2);
    position: absolute;
    bottom: 5px;
    left: 0;
}
.TextField_TextField__2_w6W textarea + span::after {
    position: absolute;
    left: -100%;
    content: '';
    height: 3px;
    width: 100%;
    background: var(--primary);
    transition: 150ms;
}
.TextField_TextField__2_w6W textarea:focus {
    outline: 0;
}
.TextField_TextField__2_w6W textarea:focus + span::after,
.TextField_TextField__2_w6W textarea:valid + span::after {
    left: 0;
}
.TextField_TextField__2_w6W textarea:focus ~ label:nth-of-type(1),
.TextField_TextField__2_w6W textarea:valid ~ label:nth-of-type(1) {
    top: -30px;
    color: var(--primary);
}

@media (max-width: 440px) {
    .TextField_TextField__2_w6W > form > label {
        font-size: 12px;
    }
}

.AdviceInpOut_AdviceInpOut__1t1ax {
    padding: 30px;
    position: relative;
}
.AdviceInpOut_wrap__3_Qe2 {
    width: 80%;
}

.AdviceInpOut_AdviceInpOut__1t1ax > .AdviceInpOut_wrap__3_Qe2 > button {
    display: block;
    border: none;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    border-radius: 2px;
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
}
.AdviceInpOut_AdviceInpOut__1t1ax > .AdviceInpOut_wrap__3_Qe2 > button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

@media (max-width: 440px) {
    .AdviceInpOut_AdviceInpOut__1t1ax {
        padding: 10px;
        position: relative;
    }

    .AdviceInpOut_AdviceInpOut__1t1ax .AdviceInpOut_wrap__3_Qe2 button {
        display: block;
        margin: 10px auto;
        font-size: 12px;
        padding: 6px 30px;
    }

    .AdviceInpOut_wrap__3_Qe2 {
        width: 100%;
    }
}

.Advice_AdvicePrev__k7ttK {
    position: relative;
}
.Advice_AdvicePrev__k7ttK .Advice_del__3TDpg {
    position: absolute;
    bottom: -50px;
    left: 0;
    color: var(--red);
    background: var(--white);
    border: 1px solid var(--red);
    font-size: 12px;
    cursor: pointer;
}
.Advice_AdvicePrev__k7ttK .Advice_del__3TDpg:hover {
    background: var(--red);
    color: var(--white);
}
.Advice_AdvicePrev__k7ttK ol {
    padding-left: 20px;
    font-size: 12px;
}
.Advice_AdvicePrev__k7ttK ol li {
    margin-bottom: 10px;
}

.ChiefComplaintsInp_ChiefComplaintsInp__3Go_f {
    padding: 15px 80px 15px 30px;
    position: relative;
}
.ChiefComplaintsInp_ChiefComplaintsInp__3Go_f button {
    display: block;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
}
.ChiefComplaintsInp_ChiefComplaintsInp__3Go_f button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

@media (max-width: 440px) {
    .ChiefComplaintsInp_ChiefComplaintsInp__3Go_f {
        padding: 10px;
        position: relative;
    }

    .ChiefComplaintsInp_ChiefComplaintsInp__3Go_f button {
        display: block;
        margin: 10px auto;
        font-size: 12px;
        padding: 6px 30px;
    }
}

.DiagnosisInp_DiagnosisInp__tKRv8 {
    padding: 30px;
}
.DiagnosisInp_DiagnosisInp__tKRv8 button {
    display: block;
    border: none;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    border-radius: 4px;
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
    margin-bottom: 30px;
}
.DiagnosisInp_DiagnosisInp__tKRv8 button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

.CoMorbidity_CoMorbidity__5MfiB h4 {
    margin-bottom: 5px;
    color: var(--dark-blue);
    position: relative;
}
.CoMorbidity_CoMorbidity__5MfiB h4 > span {
    font-size: 16px;
    position: absolute;
    right: 100px;
}
.CoMorbidity_CoMorbidity__5MfiB > .CoMorbidity_wrapper__3YRFm > button {
    display: inline-block;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 20px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--light);
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    transition: 200ms;
}
.CoMorbidity_CoMorbidity__5MfiB > .CoMorbidity_wrapper__3YRFm > button:hover {
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
    color: var(--white);
}

.CoMorbidity_check__17NO7 {
    margin: 0 10px;
    position: relative;
    display: grid;
    grid-template-columns: 35% 20% 45%;
    grid-gap: 2px;
    gap: 2px;
    align-items: baseline;
}
.CoMorbidity_check__17NO7 p {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-blue);
    position: relative;
}
.CoMorbidity_check__17NO7 > p > span {
    position: relative;
    top: -10px;
    right: -5px;
    font-size: 16px;
    color: var(--primary);
}
.CoMorbidity_check__17NO7 > button {
    display: inline-block;
    width: 60px;
    border: 1px solid var(--primary);
    border-radius: 2px;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
}
.CoMorbidity_deactive__1zN3- {
    color: var(--primary);
    background: var(--light);
}
.CoMorbidity_check__17NO7 > button:hover,
.CoMorbidity_active__QUQXW {
    background: var(--primary);
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
}
.CoMorbidity_check__17NO7 > input {
    font-size: 12px;
    padding: 5px;
    border: 1px solid var(--grey2);
    background: var(--light);
    padding: 10px;
}
.CoMorbidity_check__17NO7 > input:focus, .CoMorbidity_check__17NO7 > input:hover{
    outline: 0;
    border: 1px solid var(--secondary);
}

.HistoryInp_HistoryInp__NZLzb {
    padding: 30px;
}
.HistoryInp_wrap__OBKjb {
    width: 90%;
}
.HistoryInp_wrap__OBKjb h4 {
    color: var(--dark-blue);
    margin: 20px 0;
    cursor: pointer;
    position: relative;
    padding-left: 15px;
    display: block;
    font-weight: 400;
}
.HistoryInp_wrap__OBKjb h4::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 0;
    top: 8px;
    border-left: 7px solid var(--dark-blue);
    border-top: 5px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid transparent;
}
.HistoryInp_wrap__OBKjb > button,
.HistoryInp_btn__2VS9k {
    display: block;
    border: none;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    border-radius: 4px;
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
}
.HistoryInp_wrap__OBKjb > button:hover,
.HistoryInp_btn__2VS9k:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

.InputSection_InputSection__1IF_o > h3 {
    margin: 0;
    padding: 0;
    color: var(--primary);
    font-size: 16px;
    padding-left: 30px;
    position: relative;
}
.InputSection_InputSection__1IF_o > h3::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 15px solid var(--primary);
    border-right: 12px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 12px solid var(--primary);
    z-index: -100;
}

@media (max-width: 440px) {
    .InputSection_InputSection__1IF_o {
        margin-top: 2px;
        width: 100%;
    }
}

.InvestigationInp_InvestigationInp__3DTca {
    padding: 30px;
}

.InvestigationInp_InvestigationInp__3DTca > button {
    color: var(--white);
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    padding: 7px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}
.InvestigationInp_InvestigationInp__3DTca > button:hover {
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

@media (max-width: 440px) {
    .InvestigationInp_InvestigationInp__3DTca {
        padding: 10px;
        position: relative;
    }

    .InvestigationInp_InvestigationInp__3DTca button {
        display: block;
        margin: 10px auto;
        font-size: 12px;
        padding: 6px 30px;
    }
}

.DropDown_DropDown__fDYED {
    position: relative;
}
.DropDown_DropDown__fDYED select {
    border: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 10px;
    background: var(--light);
    border-bottom: 2px solid var(--light);
    cursor: pointer;
}
.DropDown_DropDown__fDYED select:focus {
    outline: 0;
}
.DropDown_DropDown__fDYED select:hover {
    border-bottom: 2px solid var(--primary);
    transition: 200px;
}
/* .DropDown select + span {
    position: absolute;
    width: 0;
    height: 0;
    top: 20px;
    right: 10px;
    border-top: 5px solid var(--grey);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    pointer-events: none;
}

.DropDown select:hover,
.DropDown select:hover option {
    cursor: pointer;
} */

.InputField_InputField__3RO0e {
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}
.InputField_InputField__3RO0e > input {
    border: none;
    outline: 0;
    font-size: 14px;
    box-sizing: border-box;
    padding: 25px 10px 5px 10px;
    width: 100%;
    background: var(--light);
    color: var(--dark);
    border-radius: 4px;
}
.InputField_InputField__3RO0e label {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid var(--grey2);
    pointer-events: none;
}
.InputField_InputField__3RO0e label::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -100%;
    width: 100%;
    height: 3px;
    background: var(--primary);
    transition: 200ms;
}
.InputField_InputField__3RO0e label span {
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-size: 18px;
    transition: 200ms;
    color: var(--grey);
}
.InputField_InputField__3RO0e input:focus + label span,
.InputField_InputField__3RO0e input:valid + label span {
    bottom: 28px;
    font-size: 12px;
}
.InputField_InputField__3RO0e input:focus + label::after {
    left: 0;
}

@media (max-width: 440px) {
    .InputField_InputField__3RO0e > input {
        border: none;
        outline: 0;
        font-size: 12px;
        box-sizing: border-box;
        padding: 25px 10px 5px 10px;
        width: 100%;
        background: var(--light);
        color: var(--dark);
        border-radius: 4px;
    }
}

.InputNumber_InputNumber__3GErJ {
    position: relative;
}

.InputNumber_InputNumber__3GErJ .InputNumber_wrap__36KKt {
    display: flex;
    justify-content: center;
}
.InputNumber_InputNumber__3GErJ > .InputNumber_wrap__36KKt > input[type='number'] {
    border: none;
    border-radius: 4px;
    outline: 0;
    box-sizing: border-box;
    padding: 20px 10px 10px 10px;
    width: 100%;
    background: var(--light);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
.InputNumber_InputNumber__3GErJ input[type='number']::-webkit-outer-spin-button,
.InputNumber_InputNumber__3GErJ input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.InputNumber_InputNumber__3GErJ input + label {
    position: absolute;
    font-size: 12px;
    top: 16px;
    color: var(--grey);
    pointer-events: none;
    transition: 200ms;
}
.InputNumber_InputNumber__3GErJ input:focus + label,
.InputNumber_InputNumber__3GErJ input:valid + label {
    font-size: 10px;
    top: 16px;
    color: var(--primary);
    opacity: 0;
}

.SuggestionMedicine_SuggestionMedicine__2USjW {
    position: relative;
}
.SuggestionMedicine_SuggestionMedicine__2USjW ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    background: var(--white);
    box-shadow: 1px 5px 10px var(--light-grey);
    border: 1px solid var(--light-grey);
    border-radius: 2px;
    position: absolute;
    z-index: 1000;
    top: -7px;
    min-height: 20px;
    max-height: 160px;
    overflow-y: scroll;
}
.SuggestionMedicine_SuggestionMedicine__2USjW ul li {
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--grey);
}
.SuggestionMedicine_SuggestionMedicine__2USjW ul li:hover {
    background: var(--light);
}

.InputMedicine_InputMedicine__3LU4T {
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 25px;
}
/* .InputMedicine input {
    padding: 15px 5px 5px 10px;
    font-size: 12px;
} */
.InputMedicine_InputMedicine__3LU4T label span {
    font-size: 12px;
}
.InputMedicine_InputMedicine__3LU4T input:focus + label span,
.InputMedicine_InputMedicine__3LU4T input:valid + label span {
    font-size: 10px;
}
.InputMedicine_InputMedicine__3LU4T .InputMedicine_formWrap__7wOkN input[type='number'] {
    padding: 17px 10px 5px 10px;
    font-size: 12px;
}
.InputMedicine_InputMedicine__3LU4T .InputMedicine_formWrap__7wOkN input[type='number'] + label {
    top: 16px;
}
.InputMedicine_InputMedicine__3LU4T .InputMedicine_formWrap__7wOkN input:focus + label,
.InputMedicine_InputMedicine__3LU4T .InputMedicine_formWrap__7wOkN input:valid + label {
    top: 2px;
}
.InputMedicine_InputMedicine__3LU4T .InputMedicine_formWrap__7wOkN select {
    font-size: 12px;
}

.InputMedicine_formWrap__7wOkN {
    box-sizing: border-box;
}
.InputMedicine_formWrap__7wOkN > div {
    margin-bottom: 10px;
}
.InputMedicine_typeWrap__muh7p {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 70%);
    grid-gap: 10px;
    gap: 10px;
}
.InputMedicine_typeWrap__muh7p > div {
    margin-bottom: 10px;
}
.InputMedicine_timeWrap__3Dz1z {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
}
.InputMedicine_timeWrap__3Dz1z > div {
    width: 100%;
}
.InputMedicine_daysWrap__1tVQf {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

.InputMedicine_remark__VrsUg {
    width: 100%;
}
.InputMedicine_InputMedicine__3LU4T button {
    display: block;
    border: none;
    outline: 0;
    background: var(--primary);
    color: var(--white);
    border-radius: 2px;
    padding: 7px 20px;
    margin: 0 auto;
    min-width: 150px;
}
.InputMedicine_InputMedicine__3LU4T button {
    cursor: pointer;
    box-shadow: 2px 2px 5px var(--grey2);
}

.InputMedicine_continue__179HW {
    display: flex;
    justify-content: flex-end;
    margin: -6px 0px 4px;
    color: var(--dark);
    align-items: center;
}

.InputMedicine_continue__179HW > input[type='checkbox'] {
    cursor: pointer;
}
.InputMedicine_continue__179HW > label {
    font-size: 13px;
}

.InputMedicine_select__34Pcg > select {
    width: 100%;
    cursor: pointer;
    padding: 10px;
    background-color: var(--light);
    color: var(--dark);
    border: none;
    outline: none;
    border-bottom: 2px solid var(--light);
}

.InputMedicine_select__34Pcg > select:focus {
    outline: 0;
}

.InputMedicine_select__34Pcg > select:hover {
    border-bottom: 2px solid var(--primary);
    transition: 200px;
}

.InputMedicine_select__34Pcg > select > option {
    cursor: pointer;
}

@media (max-width: 440px) {
    .InputMedicine_InputMedicine__3LU4T {
        padding: 2px;
    }

    .InputMedicine_typeWrap__muh7p {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4px;
        gap: 4px;
    }

    .InputMedicine_timeWrap__3Dz1z {
        grid-template-columns: repeat(3, minmax(0, 33%));
        grid-gap: 4px;
        gap: 4px;
    }

    .InputMedicine_InputMedicine__3LU4T button {
        font-size: 12px;
        margin-top: -10px;
    }

    .InputMedicine_continue__179HW {
        display: flex;
        justify-content: flex-end;
        margin: -6px 0px 4px;
        color: var(--dark);
        align-items: center;
        margin-bottom: 6px;
        padding-right: 3em;
    }

    .InputMedicine_continue__179HW > input[type='checkbox'] {
        display: inline-block;
        margin-top: -3em;
    }
    .InputMedicine_continue__179HW > label {
        display: inline-block;
        margin-top: -3.3em;
    }
}

.MedicineInpOut_MedicineInpOut__1aYEA {
    padding: 15px;
}

@media (max-width: 440px) {
    .MedicineInpOut_MedicineInpOut__1aYEA {
        padding: 14px 8px;
        height: 22em;
    }
}

.PreviewMedicine_PreviewMedicine__3rGTL {
    position: relative;
}
.PreviewMedicine_PreviewMedicine__3rGTL h4 {
    margin-left: 30px;
    color: var(--primary);
}

.PreviewMedicine_PreviewMedicine__3rGTL .PreviewMedicine_del__jFM7k {
    position: absolute;
    top: 5px;
    right: 200px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--red);
    background: transparent;
    font-size: 16px;
    cursor: pointer;
}
.PreviewMedicine_PreviewMedicine__3rGTL:hover .PreviewMedicine_del__jFM7k {
    display: block;
}

.PreviewMedicine_medicine__3VPRN {
    /* border: 1px solid var(--primary); */
    box-shadow: 2px 1px 5px var(--grey2);
    border-radius: 4px;
    padding: 5px 10px;
    min-height: 100px;
    box-sizing: border-box;
    margin-left: 25px;
    margin-bottom: 20px;
    width: 70%;
    transition: 200ms;
    background: var(--white);
    border-left: 4px solid var(--primary);
}
.PreviewMedicine_medicine__3VPRN:hover {
    box-shadow: 2px 1px 10px var(--grey2);
}
.PreviewMedicine_medicine__3VPRN p {
    font-size: 12px;
    margin: 2px 0;
    color: var(--dark);
    padding-left: 6px;
    padding-top: 2px;
}
.PreviewMedicine_medicine__3VPRN p span {
    margin: 0 10px;
}
.PreviewMedicine_medicine__3VPRN p:first-child {
    font-weight: 500;
    margin: 6px 0;
}
.PreviewMedicine_medicine__3VPRN > p:nth-child(2),
.PreviewMedicine_medicine__3VPRN > p:nth-child(3) {
    margin-left: 30px;
}
.PreviewMedicine_btn__1xvve {
    position: relative;
    float: right;
    right: 10px;
    top: -3.2em;
    opacity: 0.95;
}

.PreviewMedicine_btn__1xvve button {
    position: relative;
    display: block;
    font-size: 11px;
    padding: 4px 6px 2px;
    min-width: 70px;
    margin-top: 5px;
    cursor: pointer;
    border: none;
    color: var(--white);
    background-color: var(--secondary);
    border-radius: 2px;
}
.PreviewMedicine_btn__1xvve button:nth-child(1) {
    background: var(--red);
    color: var(--white);
}

.PreviewMedicine_btn__1xvve button:hover {
    opacity: 0.8;
    cursor: pointer;
    transition: 200ms;
}

.PreviewMedicine_btn__1xvve .PreviewMedicine_mob__1EPhW {
    display: none;
}

@media (max-width: 440px) {
    .PreviewMedicine_PreviewMedicine__3rGTL {
        position: relative;
        padding: 0 6px;
        margin-top: 4em;
    }

    .PreviewMedicine_PreviewMedicine__3rGTL > h4 {
        display: none;
    }

    .PreviewMedicine_list__xsc3w {
        height: 20em;
        overflow: auto;
        padding-top: 10px;
    }

    .PreviewMedicine_medicine__3VPRN {
        margin-left: 0px;
        width: 100%;
        background: transparent;
        border-left: 4px solid var(--primary);
        position: relative;
    }

    .PreviewMedicine_medicine__3VPRN p {
        font-size: 12px;
        margin: 2px 0;
        color: var(--dark);
        padding-left: 6px;
        padding-top: 2px;
    }

    .PreviewMedicine_PreviewMedicine__3rGTL .PreviewMedicine_del__jFM7k {
        position: absolute;
        top: 0px;
        right: 10px;
        display: none;
        border: none;
        outline: 0;
        border-radius: 50%;
        color: var(--white);
        background: var(--red);
        font-size: 12px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .PreviewMedicine_btn__1xvve button {
        display: none;
    }

    .PreviewMedicine_btn__1xvve {
        right: 6px;
        top: -2px;
    }

    .PreviewMedicine_btn__1xvve .PreviewMedicine_mob__1EPhW {
        display: block;
        background-color: transparent;
        color: var(--red);
        padding: 4px 8px;
        min-width: 20px;
        border-radius: 50px;
        border: none;
        font-size: 18px;
        font-weight: 500;
        margin-top: -3.5em;
        float: right;
        right: -14px;
    }
}

.NextFollowUpInpOut_NextFollowUpInpOut__1YYrN {
    padding: 30px;
    width: 80%;
    grid-gap: 15px;
    gap: 15px;
}
.NextFollowUpInpOut_NextFollowUpInpOut__1YYrN .NextFollowUpInpOut_days__2lQIo {
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
}
.NextFollowUpInpOut_NextFollowUpInpOut__1YYrN button {
    display: inline-block;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
    min-width: 100px;
}
.NextFollowUpInpOut_NextFollowUpInpOut__1YYrN button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}

.NextFollowUpInpOut_nextP__1sQoQ {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: 5px 15px;
    border-radius: 4px;
    margin-top: 30px;
}

.ExamField_ExamField__1cZh- {
    margin-bottom: 5px;
}
.ExamField_ExamField__1cZh- p {
    font-size: 12px;
    font-weight: 500;
    color: var(--dark-blue);
}

.ExamField_wrap__3Q5KF {
    display: grid;
    grid-template-columns: 25% 40% 20% 15%;
    grid-gap: 5px;
    gap: 5px;
}
.ExamField_wrap1__eNwFO {
    display: grid;
    grid-template-columns: 25% 10% 65%;
    grid-gap: 5px;
    gap: 5px;
}
.ExamField_wrap2___zyNZ {
    display: grid;
    grid-template-columns: 25% 20% 55%;
    grid-gap: 5px;
    gap: 5px;
}
.ExamField_wrap3__mVnWt {
    display: grid;
    grid-template-columns: 25% 20% 20% 35%;
    grid-gap: 5px;
    gap: 5px;
}
.ExamField_wrap4__2cPaE {
    display: grid;
    grid-template-columns: 25% 20% 2% 20% 33%;
    grid-gap: 5px;
    gap: 5px;
}
.ExamField_wrap4__2cPaE span {
    position: relative;
    top: 10px;
    color: var(--grey);
}
.ExamField_wrap5__1INv2 {
    display: grid;
    grid-template-columns: 25% 20% 5% 20% 10% 20%;
    grid-gap: 5px;
    gap: 5px;
}

input.ExamField_onExam__2jo6- {
    box-sizing: border-box;
    -webkit-appearance: none;
            appearance: none;
    border: none;
    color: var(--dark-blue);
    background: var(--light);
    padding: 0 5px;
    font-size: 12px;
}
input.ExamField_onExam__2jo6-:focus {
    outline: 0;
}
input.ExamField_onExam__2jo6-::placeholder {
    font-size: 12px;
}
input.ExamField_onExam__2jo6-[type='text'] {
    border: none;
    border-bottom: 2px solid var(--grey2);
}
input.ExamField_onExam__2jo6-[type='text']:hover,
input.ExamField_onExam__2jo6-[type='text']:valid {
    border-bottom: 2px solid var(--primary);
}

input.ExamField_onExam__2jo6-[type='number'] {
    border: none;
    border-bottom: 2px solid var(--grey2);
    padding-left: 25px;
}
input.ExamField_onExam__2jo6-[type='number']:hover,
input.ExamField_onExam__2jo6-[type='number']:valid {
    border-bottom: 2px solid var(--primary);
}

select.ExamField_selectExam__2kMNB {
    border: none;
    background: var(--light);
    border-bottom: 2px solid var(--primary);
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 7px;
    width: 100%;
    padding-left: 40%;
    position: relative;
}
select.ExamField_selectExam__2kMNB:focus {
    outline: 0;
}
select.ExamField_selectExam__2kMNB > option {
    cursor: pointer;
}

.OnExaminationInp_OnExaminationInp__iWc9j {
    padding: 30px;
}
.OnExaminationInp_btnWrapper__3E42C {
    width: 50%;
    margin: 0 auto;
}
.OnExaminationInp_btnWrapper__3E42C button {
    border: 2px solid var(--primary);
    border-radius: 2px;
    background: var(--primary);
    color: var(--white);
    cursor: pointer;
    font-size: 12px;
    padding: 7px 25px;
    margin: 30px 5px;
}
.OnExaminationInp_btnWrapper__3E42C button:nth-child(1) {
    background: var(--white);
    color: var(--primary);
    border: 2px solid var(--primary);
}

.Refer_Refer__2JRaj{
    padding: 30px;
}
.Refer_Refer__2JRaj button {
    display: inline-block;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to top, var(--primary), var(--secondary));
    color: var(--white);
    padding: 7px 20px;
    transition: 200ms;
    margin-top: 15px;
    min-width: 100px;
}
.Refer_Refer__2JRaj button:hover {
    cursor: pointer;
    background-image: linear-gradient(to top, var(--secondary), var(--primary));
}
.Refer_Refer__2JRaj > h3{
    margin-bottom: 0;
}
.Refer_detail__3-WNt{
    font-size: 14px;
    margin-top: 5px;
    color: var(--primary);
    font-weight: 600;
}
.Refer_detail__3-WNt > span{
    color: var(--dark);
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 h3 {
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 .ChiefComplaintsPrev_del__1WZHK {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2:hover .ChiefComplaintsPrev_del__1WZHK {
    display: block;
}

.ChiefComplaintsPrev_toggle__28Zjl::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 ul {
    list-style-type: none;
    margin: 5px 0;
    padding: 0 0 0 25px;
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 ul li {
    font-size: 12px;
    position: relative;
}
.ChiefComplaintsPrev_ChiefComplaintsPrev__1dpv2 ul li::before {
    position: absolute;
    left: -20px;
    top: 5px;
    content: '';
    width: 10px;
    height: 5px;
    /* border-left: 2px solid var(--primary); */
    border-bottom: 2px solid var(--dark-blue);
    transform: rotate(315deg);
}
/* .ChiefComplaintsPrev ul li span {
    margin-left: 5px;
    font-size: 11px;
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 2px;
    padding: 0 5px;
    display: none;
}
.ChiefComplaintsPrev ul li:hover span {
    display: inline-block;
    cursor: pointer;
} */

.DiagnosisPrev_DiagnosisPrev__N_I7k {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.DiagnosisPrev_DiagnosisPrev__N_I7k .DiagnosisPrev_del__3Lovn {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.DiagnosisPrev_DiagnosisPrev__N_I7k:hover .DiagnosisPrev_del__3Lovn {
    display: block;
}
.DiagnosisPrev_DiagnosisPrev__N_I7k h3 {
    display: inline-block;
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.DiagnosisPrev_DiagnosisPrev__N_I7k h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.DiagnosisPrev_toggle__W4pbO::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.DiagnosisPrev_DiagnosisPrev__N_I7k ol {
    margin: 0;
    padding: 0 15px;
}
.DiagnosisPrev_DiagnosisPrev__N_I7k ol li {
    font-size: 12px;
    margin: 5px 0;
    text-align: justify;
}

.DiagnosisPrev_diagnosis__1OGsc div {
    border: 1px solid var(--light-grey);
    background-color: var(--light-grey);
    margin: 10px 20px 15px 0;
    padding: 10px;
    border-radius: 5px;
}
.DiagnosisPrev_diagnosis__1OGsc div h4 {
    margin: 0 0 5px 0;
    padding: 0 0 0 0;
    font-weight: 400;
    display: inline-block;
    color: var(--dark-blue);
    border-bottom: 3px solid var(--light-grey);
}
.DiagnosisPrev_diagnosis__1OGsc div p {
    margin-top: 0;
    margin-bottom: 0;
}

.HistoryPrev_HistoryPrev__1K0PC {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
}
.HistoryPrev_HistoryPrev__1K0PC h3 {
    display: inline-block;
    font-size: 16px;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.HistoryPrev_HistoryPrev__1K0PC h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.HistoryPrev_toggle__37PiL::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.HistoryPrevChild_HistoryPrev__3sW7K {
    position: relative;
}
.HistoryPrevChild_HistoryPrev__3sW7K .HistoryPrevChild_del__1hbUf {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.HistoryPrevChild_HistoryPrev__3sW7K:hover .HistoryPrevChild_del__1hbUf {
    display: block;
}

.HistoryPrevChild_HistoryPrev__3sW7K .HistoryPrevChild_historyChildH__255UY {
    margin: 10px 0 0 20px;
    color: var(--dark-blue);
    display: inline-block;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    font-size: 14px;
}
.HistoryPrevChild_historyChildH__255UY::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid var(--dark-blue);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    right: -17px;
    top: 12px;
    display: none;
}
.HistoryPrevChild_historyChildH__255UY::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--dark-blue);
    border-left: 5px solid transparent;
    position: absolute;
    right: -17px;
    top: 6px;
}
.HistoryPrevChild_toggle__1-WaR::before {
    display: block;
}
.HistoryPrevChild_toggle__1-WaR::after {
    display: none;
}
.HistoryPrevChild_HistoryPrev__3sW7K .HistoryPrevChild_historyli__P3A0P {
    list-style-type: none;
    margin: 10px 0 0 20px;
    padding: 0 0 0 25px;
}
.HistoryPrevChild_HistoryPrev__3sW7K .HistoryPrevChild_historyli__P3A0P li {
    font-size: 12px;
    position: relative;
}
.HistoryPrevChild_HistoryPrev__3sW7K .HistoryPrevChild_historyli__P3A0P li::before {
    position: absolute;
    left: -15px;
    top: -12px;
    content: '';
    width: 10px;
    height: 100%;
    border-left: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
}

.HistoryPrevChild_combrth__1Zd_T {
    color: var(--dark-blue);
    margin: 20px 0 5px 20px;
}
.HistoryPrevChild_combrt__2fjxg {
    list-style-type: none;
    margin-top: 0;
    margin-left: 30px;
    padding: 0;
}
.HistoryPrevChild_combrt__2fjxg li {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    color: var(--primary);
    position: relative;
}
.HistoryPrevChild_combrt__2fjxg li span {
    font-weight: 400;
    color: var(--dark-blue);
    position: relative;
    margin-left: 10px;
}
.HistoryPrevChild_combrt__2fjxg li span::before {
    content: '+ ';
    color: var(--primary);
    position: absolute;
    top: -12px;
    left: -12px;
}

.InvestigationPrev_InvestigationPrev__HXaNa {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.InvestigationPrev_InvestigationPrev__HXaNa .InvestigationPrev_del__UtHaq {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.InvestigationPrev_InvestigationPrev__HXaNa:hover .InvestigationPrev_del__UtHaq {
    display: block;
}
.InvestigationPrev_InvestigationPrev__HXaNa h3 {
    display: inline-block;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.InvestigationPrev_InvestigationPrev__HXaNa h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.InvestigationPrev_toggle__2_aLg::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.InvestigationPrev_InvestigationPrev__HXaNa ul {
    list-style-type: none;
    margin: 0 0 0 10px;
    padding: 0;
}
.InvestigationPrev_InvestigationPrev__HXaNa ul li {
    font-size: 12px;
    margin-left: 18px;
    position: relative;
}
.InvestigationPrev_InvestigationPrev__HXaNa ul li::before {
    position: absolute;
    top: 5px;
    left: -16px;
    content: '';
    width: 8px;
    height: 8px;
    background: var(--primary);
}
.InvestigationPrev_InvestigationPrev__HXaNa ul li::after {
    position: absolute;
    top: 3px;
    left: -18px;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid var(--primary);
}

.OnExaminationPrev_OnExaminationPrev__3wtgq {
    margin-top: 25px;
    padding-left: 25px;
    border-left: 3px solid var(--primary);
    position: relative;
}
.OnExaminationPrev_OnExaminationPrev__3wtgq .OnExaminationPrev_del__JURUM {
    position: absolute;
    top: 5px;
    right: 15px;
    display: none;
    border: none;
    outline: 0;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
    font-size: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.OnExaminationPrev_OnExaminationPrev__3wtgq:hover .OnExaminationPrev_del__JURUM {
    display: block;
}

.OnExaminationPrev_OnExaminationPrev__3wtgq h3 {
    font-size: 16px;
    display: inline-block;
    margin: 5px 0;
    color: var(--primary);
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
.OnExaminationPrev_OnExaminationPrev__3wtgq h3::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid var(--primary);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    top: 12px;
    right: -25px;
}
.OnExaminationPrev_toggle__jtqAt::after {
    transform: rotate(180deg);
    top: 6px !important;
}

.OnExaminationPrev_OnExaminationPrev__3wtgq ul {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 0;
}
.OnExaminationPrev_OnExaminationPrev__3wtgq ul li {
    font-size: 12px;
    margin-left: 25px;
    margin-bottom: 4px;
    position: relative;
    color: var(--dark);
}
.OnExaminationPrev_OnExaminationPrev__3wtgq ul li::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid var(--primary);
    background: var(--white);
    top: 3px;
    left: -17px;
    z-index: 100;
}

.OnExaminationPrev_OnExaminationPrev__3wtgq ul li::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--primary);
    top: 6px;
    left: -14px;
}
.OnExaminationPrev_OnExaminationPrev__3wtgq ul li span {
    position: absolute;
    left: 100px;
    font-weight: 400;
    color: var(--dark-blue);
}

.PreviewSection_PreviewSection__1PJ5i h3 {
    margin: 0;
    padding: 0;
    color: var(--primary);
    position: relative;
    font-size: 16px;
    font-weight: 500;
}
.PreviewSection_PreviewSection__1PJ5i h4 {
    font-weight: 500;
    font-size: 14px;
}
.PreviewSection_PreviewSection__1PJ5i > h3 {
    padding-left: 30px;
}
.PreviewSection_PreviewSection__1PJ5i > h3::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 15px solid var(--primary);
    border-right: 12px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 12px solid var(--primary);
    z-index: -100;
}

@media (max-width: 440px) {
    .PreviewSection_PreviewSection__1PJ5i {
        margin-top: 30px;
    }
}

.Body_Body__3G-xz {
    position: relative;
}
.Body_Body__3G-xz > button {
    position: absolute;
    top: 40vh;
    left: 40%;
    border: none;
    outline: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--white);
    background: var(--primary);
    border-radius: 50px;
    padding: 5px;
    width: 40px;
    height: 40px;
    margin: 0 0 0 -20px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;
}
.Body_Body__3G-xz > button > svg {
    transform: rotate(90deg);
    font-size: 22px;
    line-height: 28px;
}
.Body_Body__3G-xz > button:hover {
    transform: rotate(360deg);
}
.Body_Wrapper__1Iiw5 {
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);
    height: 100vh;
}
.Body_Wrapper__1Iiw5 > div {
    overflow-y: auto;
}
.Body_Wrapper__1Iiw5 > div:first-child {
    border-right: 1px solid var(--light-grey);
    height: 100vh;
    overflow-y: auto;
}
.Body_clearAll__35i8w {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: var(--white);
    background: var(--primary);
    font-size: 12px;
    padding: 7px 20px 6px;
    border-radius: 50px;
    cursor: pointer;
}

@media (max-width: 440px) {
    .Body_Body__3G-xz {
        overflow-y: auto;
        min-height: 30em;
        margin-top: -10px;
        width: 100%;
    }

    .Body_Wrapper__1Iiw5 {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-bottom: -2em;
        min-height: 20em;
    }
    .Body_Wrapper__1Iiw5 > div {
        overflow-y: visible;
    }

    .Body_Body__3G-xz > button {
        display: none;
    }

    .Body_clearAll__35i8w {
        bottom: 82px;
        right: 14px;
        font-size: 10px;
        padding: 3px 10px 2px;
    }
}

.Patient_Patient__3X0TF {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: initial;
}
.Patient_Patient__3X0TF::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 110vh;
    background: var(--dark-blue);
    opacity: 0.3;
    z-index: 150;
}

.Patient_Patient__3X0TF form {
    position: absolute;
    top: 30%;
    width: 40%;
    z-index: 2000;
    background: var(--white);
    transform: translate(70%, -35%);
    padding: 40px 20px;
    border-radius: 5px;
    box-shadow: 1px 5px 15px var(--dark);
    transition: 300ms;
    z-index: 151;
}
.Patient_Patient__3X0TF > form > h2 {
    text-align: center;
}
.Patient_Patient__3X0TF > form > span {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--white);
    background: var(--grey2);
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: 2px;
}
.Patient_Patient__3X0TF > form > span:hover {
    background: var(--red);
}

.Patient_close__3RG4R {
    color: var(--red);
    position: absolute;
    top: 8.2em;
    left: 4.8em;
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
}

.Patient_close__3RG4R:hover {
    opacity: 0.8;
    transition: 200ms;
    cursor: pointer;
}

.Patient_Wrapper__3tNss {
    padding: 0 50px;
    z-index: 153;
}
.Patient_Wrapper__3tNss input {
    padding: 7px 10px;
    font-size: 14px;
    margin: 7px 0;
    border: 1px solid var(--light);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background: var(--light);
    color: var(--dark);
    z-index: 154;
}
.Patient_Wrapper__3tNss input:focus {
    border: 1px solid var(--primary);
    outline: 0;
}
.Patient_Wrapper__3tNss > input:nth-child(1) {
    border: 1px solid var(--primary);
    transform: scale(1.05);
    transition: 300ms;
}
.Patient_Wrapper__3tNss > button {
    display: block;
    width: 100%;
    background: var(--primary);
    cursor: pointer;
    border: none;
    color: var(--white);
    padding: 10px;
    border-radius: 2px;
    margin-top: 10px;
}
.Patient_Wrapper__3tNss > button:focus {
    outline: 0;
}

.Patient_Two__1scse {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    grid-gap: 10px;
    gap: 10px;
}
.Patient_Wrapper__3tNss select {
    border-radius: 4px;
    border: 1px solid var(--light);
    background: var(--light);
    padding: 7px 10px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 7px 0;
    color: var(--dark);
}
.Patient_Wrapper__3tNss select:focus {
    outline: 0;
    border: 1px solid var(--primary);
}

.Patient_clear__1KgnI {
    position: absolute;
    top: 9.3em;
    right: 66px;
    background-color: var(--red);
    color: var(--white);
    padding: 0px 10px 2px;
    border-radius: 4px;
    font-size: 14px;
}

.Patient_clear__1KgnI:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 200ms;
}

@media (max-width: 440px) {
    .Patient_Patient__3X0TF {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 100;
    }

    .Patient_Patient__3X0TF form {
        top: 30%;
        width: 80%;
        z-index: 2000;
        transform: translate(2%, -35%);
        padding: 40px 28px;
        padding-bottom: 30px;
    }

    .Patient_Patient__3X0TF > form > h2 {
        text-align: center;
        font-size: 18px;
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .Patient_Patient__3X0TF > form > span {
        background: var(--red);
        top: 20px;
    }

    .Patient_close__3RG4R {
        color: var(--red);
        position: absolute;
        top: 5em;
        left: 2em;
        font-weight: 500;
        font-size: 12px;
        text-decoration: underline;
    }

    .Patient_Wrapper__3tNss {
        padding: 0px;
    }

    .Patient_Wrapper__3tNss input {
        font-size: 12px;
    }

    .Patient_Wrapper__3tNss select {
        font-size: 12px;
    }

    .Patient_clear__1KgnI {
        position: absolute;
        right: 30px;
        top: 7.6em;
        background-color: var(--red);
        color: var(--white);
        padding: 0px 10px 2px;
        border-radius: 4px;
        font-size: 12px;
    }
}

.PatientSearch_PatientSearch__1jLiU{
    position: relative;
}
.PatientSearch_PatientSearch__1jLiU > ul{
    margin: 0;
    padding: 0;
    position: absolute;
    top: -5px;
    z-index: 2000;   
    width: 100%;
    border: 1px solid var(--light);
    border-radius: 0 0 5px 5px;
    background: var(--white);
    box-shadow: 1px 3px 5px var(--grey2);
}
.PatientSearch_PatientSearch__1jLiU > ul > li{
    color: var(--dark);
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    cursor: pointer;
}
.PatientSearch_PatientSearch__1jLiU > ul > li:hover{
    background: var(--light);
}

.PatientSearch_PatientSearch__1jLiU > ul > li>span{
    color: var(--grey);
    font-size: 12px;
    display: block;
}
.Nav_Nav__cViw5 {
    overflow: hidden;
    border-bottom: 1px solid var(--light-grey);
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);
}
.Nav_alignLeft__1l15s {
    display: inline-block;
}
.Nav_alignLeft__1l15s svg {
    color: var(--light-grey);
    margin: 12px 0;
    font-size: 28px;
    cursor: pointer;
}
.Nav_alignLeft__1l15s svg:hover {
    color: var(--secondary);
}

.Nav_patient__IoPE5 {
    display: inline-block;
    margin: 0 0 0 35px;
    position: relative;
    cursor: pointer;
    width: 80%;
}

.Nav_patient__IoPE5:hover {
    opacity: 0.8;
}

.Nav_patient__IoPE5 svg {
    font-size: 30px;
    position: absolute;
    top: 2px;
}
.Nav_add__onlD4 {
    color: var(--primary);
    position: relative;
    top: -17px;
}
.Nav_add__onlD4::after {
    content: 'Please add your patient here!';
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    top: -16px;
    left: 20px;
    color: var(--primary);
    background: var(--white);
    border: 1px solid var(--primary);
    padding: 5px 10px;
    border-radius: 2px;
    animation: Nav_pop__1346S 2s infinite;
}

.Nav_ok__1jYIY > div {
    margin-left: -1em;
    color: var(--primary);
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
}
.Nav_ok__1jYIY > div > svg {
    position: absolute;
    top: 18px;
}
.Nav_ok__1jYIY > div > p {
    margin: 0px;
    display: block;
    position: relative;
    font-weight: 500;
    top: 15px;
    left: 40px;
    font-size: 14px;
    color: var(--primary);
    border-radius: 2px;
}
.Nav_ok__1jYIY > div > p > span {
    color: var(--dark);
    display: block;
    font-size: 12px;
    opacity: 0.8;
    /* margin-bottom: 5px; */
}

.Nav_ok__1jYIY > div > p > span > b {
    padding-left: 2px;
}

.Nav_ok__1jYIY p > span {
    margin-bottom: 2px;
}

.Nav_address__fq7eO {
    margin: 20px 0 6px 5px;
    color: var(--grey);
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    max-width: 390px;
}

.Nav_address__fq7eO > span {
    font-weight: 500;
    padding-left: 2px;
    font-size: 11px;
}

.Nav_cause__1pb-O {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--primary);
    font-weight: 500;
}

.Nav_cause__1pb-O > span {
    font-size: 13px;
    padding-left: 6px;
    color: var(--red);
}

@keyframes Nav_pop__1346S {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 440px) {
    .Nav_Nav__cViw5 {
        padding-bottom: 0px;
        margin-bottom: 20px;
        grid-template-columns: minmax(0, 80%);
        width: 100%;
        margin-top: -12px;
    }

    .Nav_alignLeft__1l15s {
        visibility: hidden;
        width: 100%;
    }

    .Nav_cause__1pb-O {
        padding-left: 10px;
        padding-bottom: 4px;
        font-size: 10px;
    }
    .Nav_cause__1pb-O > span {
        font-size: 12px;
    }

    .Nav_patientDetails__1nOGR {
        margin-top: -3.8em;
    }

    .Nav_add__onlD4 {
        top: -44px;
        margin-bottom: -10px;
    }

    .Nav_ok__1jYIY {
        width: 90%;
    }

    .Nav_ok__1jYIY > div {
        grid-gap: 20px;
        gap: 20px;
    }
    .Nav_ok__1jYIY > div > svg {
        font-size: 26px;
    }
    .Nav_ok__1jYIY > div > p {
        font-size: 12px;
        text-transform: capitalize;
    }
    .Nav_ok__1jYIY > div > p > span {
        color: var(--grey);
        font-size: 10px;
    }
}

.Dashboard_Dashboard__jFBog {
    display: grid;
    grid-template-columns: minmax(0, 250px) auto;
    position: relative;
}

.Dashboard_minimize__1tVLR > div:nth-child(2) {
    position: absolute;
    /* animation: left 300ms; */
    left: 0;
    width: 100%;
}
.Dashboard_minimize__1tVLR > div:first-child {
    /* animation: op 300ms; */
    opacity: 0;
}

.Dashboard_maximize__Q-GS8 {
    position: absolute;
    /* animation: right 300ms; */
    right: inherit;
    width: 100%;
}
h2 {
    color: var(--primary);
}

.Dashboard_Dashboard__jFBog .Dashboard_mobileBtn__1_oli {
    display: none;
}

@keyframes Dashboard_right__d_ivT {
    100% {
        right: 0;
    }
    0% {
        right: 250px;
    }
}
@keyframes Dashboard_left__OWB_B {
    0% {
        left: 250px;
    }
    100% {
        left: 0;
    }
}
@keyframes Dashboard_op__295fZ {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 440px) {
    .Dashboard_Dashboard__jFBog {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .Dashboard_Dashboard__jFBog > div:nth-child(3) {
        margin-bottom: -40px;
    }

    .Dashboard_Dashboard__jFBog .Dashboard_mobileBtn__1_oli {
        border-top: 1px solid var(--light);
        display: block;
        padding-bottom: 10em;
        background: var(--white);
        z-index: 10;
    }
}

.MobileNav_mobileNav__2gmcu {
    display: none;
    position: fixed;
    background-color: var(--white);
    height: 4.5em;
    width: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    z-index: 100;
    box-shadow: 0px 2px 4px var(--light-grey), 0px -4px 20px var(--light);
}

.MobileNav_mobileNav__2gmcu > div:last-child {
    position: relative;
}

.MobileNav_navigation__L7Ggs {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.MobileNav_navigation__L7Ggs ul {
    display: flex;
    width: 380px;
    margin-left: -44px;
}

.MobileNav_navigation__L7Ggs ul li {
    position: relative;
    list-style: none;
    width: 120px;
    z-index: 1;
}

.MobileNav_navigation__L7Ggs ul li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: 500;
    color: var(--grey);
    text-decoration: none;
}

.MobileNav_navigation__L7Ggs ul li .MobileNav_icon__2oww2 {
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.2em;
    text-align: center;
    transition: 0.5s;
    transform: translateY(-10px);
}

.MobileNav_navigation__L7Ggs ul li.MobileNav_active__2_65l .MobileNav_icon__2oww2 {
    transform: translateY(-12px);
    color: var(--primary);
    transition: 0.5s;
}

.MobileNav_navigation__L7Ggs ul li:nth-child(3).MobileNav_active__2_65l .MobileNav_icon__2oww2 {
    transform: translateY(-19px);
    color: var(--primary);
    transition: 0.5s;
}

.MobileNav_navigation__L7Ggs ul li.MobileNav_active__2_65l .MobileNav_icon__2oww2 > img {
    width: 24px;
    height: 24px;
}

.MobileNav_navigation__L7Ggs ul li .MobileNav_text__29Za5 {
    position: absolute;
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 1;
    transform: translateY(10px);
    color: var(--grey);
}

.MobileNav_navigation__L7Ggs ul li.MobileNav_active__2_65l .MobileNav_text__29Za5 {
    margin-top: 10px;
    opacity: 1;
    transform: translateY(8px);
    color: var(--primary);
    transition: 0.5s;
    font-weight: 500;
}

.MobileNav_indicator__o90tj {
    position: absolute;
    top: 0%;
    width: 48px;
    height: 48px;
    background: var(--white);
    border-radius: 50%;
    border: 4px solid var(--light-low);
    margin-left: 1.6em;
}

.MobileNav_indicator__o90tj::before {
    content: '';
    position: absolute;
    top: 49%;
    left: -20px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 0 0 rgb(245, 241, 242);
}

.MobileNav_indicator__o90tj::after {
    content: '';
    position: absolute;
    top: 49%;
    right: -20px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -2px 0 0 rgb(243, 245, 241);
}

.MobileNav_navigation__L7Ggs ul li:nth-child(1).MobileNav_active__2_65l ~ .MobileNav_indicator__o90tj {
    transform: translateX(calc(114px * 0));
}

.MobileNav_navigation__L7Ggs ul li:nth-child(2).MobileNav_active__2_65l ~ .MobileNav_indicator__o90tj {
    transform: translateX(calc(120px * 1));
}

.MobileNav_navigation__L7Ggs ul li:nth-child(3).MobileNav_active__2_65l ~ .MobileNav_indicator__o90tj {
    transform: translateX(calc(68.2px * 2));
}

@media (max-width: 440px) {
    .MobileNav_mobileNav__2gmcu {
        display: flex;
    }
}

.Sidebar_Sidebar__1lme8 {
    height: 100vh;
    border-right: 1px solid var(--light-grey);
}
.Sidebar_Sidebar__1lme8 > a > button {
    border: none;
    border-radius: 50px;
    background: var(--primary);
    color: var(--white);
    padding: 5px 25px;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 25px 0 10px 32px;
    cursor: pointer;
    transition: 200ms;
}
.Sidebar_Sidebar__1lme8 > a {
    color: var(--white);
    text-decoration: none;
}
.Sidebar_Sidebar__1lme8 > a > button:hover {
    transform: scale(1.1);
    background: var(--secondary);
}
.Sidebar_epLogo__21f1H img {
    display: block;
    margin: 20px auto;
}
.Sidebar_docImg__tcSE0 {
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
    border: 4px solid var(--light-grey);
}
.Sidebar_doc__3M1r9 h3 {
    text-align: center;
    color: var(--primary);
    margin-bottom: 0;
    font-size: 16px;
}
.Sidebar_doc__3M1r9 p {
    text-align: center;
    margin-top: 5px;
    color: var(--grey);
    font-size: 12px;
}
.Sidebar_doc__3M1r9 hr {
    background-color: var(--light-grey);
    border: none;
    height: 1px;
    width: 80%;
    margin-left: 10%;
}

.Sidebar_nav__1Rx4z {
    list-style: none;
    padding: 0;
    overflow: hidden;
}

.Sidebar_mobileNav__1E91d {
    display: none;
}

.Sidebar_nav__1Rx4z li {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 0 6px 80px;
    color: var(--dark-blue);
    transition: 100ms;
    position: relative;
}
.Sidebar_nav__1Rx4z li svg {
    position: absolute;
    top: 10px;
    left: 50px;
    font-size: 12px;
}

.Sidebar_nav__1Rx4z li span {
    display: none;
}

.Sidebar_active__3OgD9 {
    /* background-color: var(--light-grey); */
    background-image: linear-gradient(to right, var(--primary) 0%, var(--white) 150%);
    color: var(--white) !important;
    cursor: pointer;
    transform: scale(1.1);
    /* color: var(--primary) !important; */
}

.Sidebar_active__3OgD9:hover {
    opacity: 0.9;
}

.Sidebar_nav__1Rx4z li:hover {
    background-color: var(--light-low);
    color: var(--primary);
    cursor: pointer;
    transform: scale(1.1);
}

@media (max-width: 440px) {
    .Sidebar_Sidebar__1lme8 {
        width: 99.5%;
        height: 100%;
    }

    .Sidebar_flex__1MN3G {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--light);
        box-shadow: 2px 4px 8px var(--light);
        width: 100%;
        z-index: 100;
        background-color: var(--white);
    }

    .Sidebar_epLogo__21f1H img {
        display: block;
        margin: 10px;
        width: 160px;
    }

    .Sidebar_doc__3M1r9 > * {
        display: none;
    }

    .Sidebar_doc__3M1r9 > div {
        display: block;
        width: 36px;
        height: 36px;
        margin: 10px;
        border: 2px solid var(--light-grey);
        margin-right: 16px;
        z-index: 500;
    }

    .Sidebar_nav__1Rx4z {
        display: none;
    }

    .Sidebar_mobileHide__2ghOQ {
        display: none;
    }
}

.Landing_Landing__3WE0r {
    width: 100vw;
    height: 100vh;
    background: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Landing_Wrap__2u_r9 {
    display: inline-block;
    background: var(--secondary);
    border-radius: 5px;
    padding: 30px 80px;
}
.Landing_Wrap__2u_r9 > h2,
.Landing_Wrap__2u_r9 > h1 {
    color: var(--white);
    font-weight: 500;
}
.Landing_Wrap__2u_r9 > h1 {
    font-size: 60px;
    margin: 0;
}
.Landing_Wrap__2u_r9 > h2 {
    margin: 10px;
    font-size: 30px;
}

@media (max-width: 440px) {
    .Landing_Wrap__2u_r9 {
        padding: 30px 40px;
        width: 70%;
    }
    .Landing_Wrap__2u_r9 > h1 {
        font-size: 30px;
        margin: 0;
        text-align: center;
    }
    .Landing_Wrap__2u_r9 > h2 {
        margin: 10px;
        font-size: 26px;
        text-align: center;
        margin-left: -10px;
    }
}

:root {
    --primary: #457dc3;
    --secondary: #2e9eff;
    --dark: #333;
    --dark-blue: #23395d;
    --dark-grey: #555;
    --grey: #7987a1;
    --grey2: #aebacf;
    --light-grey: #dee3ec;
    --light: #f5f5f5;
    --light-low: #f8f8f8;
    --white: #fff;
    --red: #ef5455;
    --green: #7ab97a;
}
* {
    font-family: Poppins;
}
body {
    margin: 0;
}
h1,
h2,
h3 {
    color: var(--primary);
}
h4,
h5 {
    color: var(--dark);
}
p {
    color: var(--dark);
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: var(--light-grey);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--grey);
}

